import React, { useEffect, useState } from 'react'
import { FaPlus, FaMinus } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

const faqs = [
    {
        "question": "question1",
        "answer": "answer1"
    },
    {
        "question": "question2",
        "answer": "answer2"
    },
    {
        "question": "question3",
        "answer": "answer3"
    },
    {
        "question": "question4",
        "answer": "answer4"
    },
    {
        "question": "question5",
        "answer": "answer5"
    },
    {
        "question": "question6",
        "answer": "answer6"
    },
    {
        "question": "question7",
        "answer": "answer7"
    },
    {
        "question": "question8",
        "answer": "answer8"
    },
    {
        "question": "question9",
        "answer": "answer9"
    },
    {
        "question": "question10",
        "answer": "answer10"
    },
    {
        "question": "question11",
        "answer": "answer11"
    },
    {
        "question": "question12",
        "answer": "answer12"
    },
    {
        "question": "question13",
        "answer": "answer13"
    },
    {
        "question": "question14",
        "answer": "answer14"
    },
    {
        "question": "question15",
        "answer": "answer15"
    },
    {
        "question": "question16",
        "answer": "answer16"
    },
    {
        "question": "question17",
        "answer": "answer17"
    },
    {
        "question": "question18",
        "answer": "answer18"
    },
    {
        "question": "question19",
        "answer": "answer19"
    },
    {
        "question": "question20",
        "answer": "answer20"
    },
    {
        "question": "question21",
        "answer": "answer21"
    },
    {
        "question": "question22",
        "answer": "answer22"
    },
    {
        "question": "question23",
        "answer": "answer23"
    },
    {
        "question": "question24",
        "answer": "answer24"
    },
]


const Faq = ({ from }) => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(null);
    const toggleFAQ = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null); // Close the currently open FAQ
        } else {
            setActiveIndex(index); // Open the clicked FAQ
        }
    };

    useEffect(() => {
        AOS.init({
            duration: 1000,
            offset: 100,
        });
    }, []);

    useEffect(() => {
        // Refresh AOS to reinitialize it after FAQ state changes
        AOS.refresh();
    }, [activeIndex]);
    return (
        <div
            className={from === 'FAQ'
                ? 'bg-white min-h-screen md:bg-custom-gradient flex pl-0 md:pl-[6vw] 3xl:pl-[16vw] pt-[100px] pb-[100px] md:pb-[200px]'
                : 'bg-[#242424] min-h-screen  pt-[100px] pb-[100px]'

            }>
            <div className={from === 'FAQ' ? 'w-full md:w-[60vw] xl:w-[60vw] 3xl:w-[40vw]' : 'w-full md:w-[60vw] xl:w-[60vw] 3xl:w-[40vw] m-auto'}>
                {from === 'FAQ'
                    ? <h2 className="text-2xl font-bold mb-6 ml-3 md:ml-0">{t("freqnlty_asked_questions")}</h2>
                    : <h2 className='text-[35px] tracking-[3px] font-[600] uppercase text-white pb-20 p-4 text-center' data-aos="fade-up" dangerouslySetInnerHTML={{ __html: t("frequently_asked_questions") }} />
                }
                {faqs.map((faq, index) => (
                    <div
                        key={index}
                        className={`border-t ${from === 'home' ? 'border-[#181818]' : 'border-gray-300'} ${index === faqs.length - 1 ? 'border-b' : ''} transition-all duration-300 ${activeIndex === index ? 'bg-beige' : ''} `}
                        data-aos={from === 'home' && 'fade-up'}
                    >
                        <div
                            className={`flex justify-between items-center cursor-pointer hover:bg-beige-light py-5 px-5  transition-all duration-300 ${from === 'home' && 'text-white'}  ${from === 'FAQ' ? `${activeIndex === index && 'bg-[#da291c]'}` : `${activeIndex === index && 'bg-[#181818]'}`}`}
                            onClick={() => toggleFAQ(index)}

                        >
                            <h3 className={`text-md md:text-lg font-semibold ${from === 'home' && `${activeIndex === index && 'text-[#da291c]'}`}`}>{t(`${faq.question}`)}</h3>
                            <span className={`border p-2 hover:bg-[#da291c] ${from === 'home' && 'border-[#da291c]'}`}>
                                {activeIndex === index ? <FaMinus /> : <FaPlus />}
                            </span>
                        </div>
                        {activeIndex === index && (
                            <p className={`mt-2 py-5 px-5  ${from === 'home' ? 'text-white' : 'text-gray-700'}`} dangerouslySetInnerHTML={{ __html: t(`${faq.answer}`) }} />
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Faq