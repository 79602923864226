import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck } from 'react-icons/ai';

const BookingPayment = ({ setPaymentSystem, nextStep }) => {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState('credit_card');

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const [isChecked, setIsChecked] = useState(false);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };
    const handleBookClick = () => {
        nextStep();
        setPaymentSystem(selectedOption);
    }
    return (
        <div className='flex flex-col gap-8'>
            <div className='bg-gray-200 p-6 min-h-[250px]'>
                <h2 className='font-bold text-[24px]'>{t('payment_methods')}</h2>
                <div className="flex items-center mt-5 ml-2">
                    <input
                        id="credit-card-radio"
                        type="radio"
                        value="credit_card"
                        name="payment-option"
                        className="w-4 h-4"
                        checked={selectedOption === 'credit_card'}
                        onChange={handleRadioChange}
                    />
                    <label htmlFor="credit-card-radio" className="ms-2 text-sm font-medium text-black">
                        {t('credit_card_in_vehicle')}
                    </label>
                </div>
                {selectedOption === 'credit_card' && (
                    <div className="mt-4 mb-4 p-2 bg-red-300">
                        {/* This div will show when "Credit Card" is selected */}
                        <p className='text-[14px]'>{t('credit_card_mssg')}</p>
                    </div>
                )}

                <div className="flex items-center mt-2 ml-2">
                    <input
                        id="cash-radio"
                        type="radio"
                        value="cash"
                        name="payment-option"
                        className="w-4 h-4"
                        checked={selectedOption === 'cash'}
                        onChange={handleRadioChange}
                    />
                    <label htmlFor="cash-radio" className="ms-2 text-sm font-medium text-black">
                        {t('paying_with_cash')}
                    </label>
                </div>
                {selectedOption === 'cash' && (
                    <div className="mt-4 p-2 bg-red-300">
                        {/* This div will show when "Cash" is selected */}
                        <p className='text-[14px]'>{t('paying_with_cash_mssg')}</p>
                    </div>
                )}

            </div>
            <div className='flex justify-start items-start gap-2 text-black'>
                <div
                    className={`w-6 h-6 border-2 rounded-sm flex items-center justify-center cursor-pointer ${isChecked ? "border-gray-400" : "border-gray-400"
                        }`}
                    onClick={toggleCheckbox}
                >
                    {isChecked && <AiOutlineCheck className="text-green-500" />}
                </div>
                <p>{t('payment_accept_our')} <a href={`/${t('term')}`} target='_blank' className='text-[#da291c]'>{t('terms_and_condition')}.</a></p>
            </div>
            {isChecked &&
                <button className="bg-[#da291c] w-[180px] uppercase hover:bg-black  text-white tracking-[2px] py-2 px-8 ml-auto" onClick={handleBookClick}>
                    {t("book_now")}
                </button>
            }
        </div>
    )
}

export default BookingPayment