import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import SingleService from './SingleService';
import { useTranslation } from 'react-i18next';
import { GiPathDistance } from "react-icons/gi";
import { GiTeamIdea } from "react-icons/gi";
import { GiCommercialAirplane } from "react-icons/gi";


const serviceData = [
    {
        image: <GiCommercialAirplane/>,
        title: 'airport_transfers',
        description: 'airport_transfers_description'
    },
    {
        image: <GiTeamIdea/>,
        title: 'business_transfers',
        description: 'business_transfers_description'
    },
    {
        image: <GiPathDistance/>,
        title: 'long_distance_travel',
        description: 'long_distance_travel_description'
    },

]

const OurServices = () => {
    const { t, } = useTranslation();

    useEffect(() => {
        AOS.init({
            duration: 1000,
            offset: 100,
        });
    }, []);
    return (
        <div className='w-full pb-16 pt-[10px] lg:pt-[150px] flex flex-col items-center bg-[#f1f1f1]'>
            <span className='text-[28px] xl:text-[40px] lg:text-[36px] font-[600] mb-2 text-center uppercase p-2 mt-8 xl:mt-0' data-aos="fade-up" >
                {t('our')} <span className='text-[#da291c]'>{"Transfers"}</span>
            </span>

            <span className='text-[16px]  text-[#555555] tracking-[1px] w-[95vw] xl:w-[74vw] 3xl:w-[60vw] m-auto font-[300] mb-[100px] p-8 xl:p-0 mt-8' data-aos="fade-up"  dangerouslySetInnerHTML={{ __html: t("servicesDetails") }} />
              
              

            <div className='flex flex-wrap xl:flex-nowrap justify-center w-[90vw] md:justify-start lg:justify-center items-start gap-16 m-auto'>
                {serviceData.map((service, index) => (
                    <SingleService key={index} service={service} index={index} />
                ))}
            </div>
        </div>
    )
}

export default OurServices