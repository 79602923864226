import React, { useEffect, useState } from 'react';
import bgMap from '../assets/images/map-switzeland.png';
import baselCarImage from '../assets/images/carsSide/mercedes-v-class-side.png';
import anotherCarImage from '../assets/images/cars/sprinter-vip.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';


import carImg1 from '../assets/images/fleetCars/m11.png'
import carImg2 from '../assets/images/fleetCars/m22.png'
import carImg3 from '../assets/images/fleetCars/m33.png'
import carImg4 from '../assets/images/fleetCars/m44.png'
import carImg5 from '../assets/images/fleetCars/m55.png'
import carImg6 from '../assets/images/fleetCars/m66.png'

const Destinations = () => {
    const { i18n,t } = useTranslation();
    const vechicles = [`Mercedes Benz ${t("e_class")}`, `Mercedes Benz ${t("s_class")}`, `Mercedes Benz V 250 4x4`, `SUV BMW X5`, 'Mercedes Benz Maybach', 'Mercedes Benz Sprinter',]
    const vechiclesImages = [carImg1, carImg2, carImg5, carImg6, carImg3, carImg4]
    const coordinates = [
        {
            destination: 'Basel',
            distance: '92km',
            duration: '1h 5min',
            vehicleImages: vechiclesImages, // Array of car images for the carousel
            vehicles: vechicles, // Names of the cars corresponding to images
            price: '423.2 CHF',
            leftCor: '36%',
            topCor: '11%',
        },
        {
            destination: 'Bern',
            distance: '126km',
            duration: '1h 26min',
            vehicleImages: vechiclesImages, // Array of car images for the carousel
            vehicles: vechicles, // Names of the cars corresponding to images
            price: '554.4 CHF',
            leftCor: '35%',
            topCor: '38%',
        },
        {
            destination: 'Genf',
            distance: '284km',
            duration: '3h 3min',
            vehicleImages: vechiclesImages, // Array of car images for the carousel
            vehicles: vechicles,
            price: '1249.6 CHF',
            leftCor: '5%',
            topCor: '72%',
        },
        {
            destination: 'Zermatt',
            distance: '254km',
            duration: '3h 40min',
            vehicleImages: vechiclesImages, // Array of car images for the carousel
            vehicles: vechicles, // Names of the cars corresponding to images
            leftCor: '41%',
            topCor: '81%',
        },
        {
            destination: 'Andermatt',
            distance: '125km',
            duration: '1h 32min',
            vehicleImages: vechiclesImages, // Array of car images for the carousel
            vehicles: vechicles, // Names of the cars corresponding to images
            price: '550.0 CHF',
            leftCor: '57%',
            topCor: '53%',
        },
        {
            destination: 'Davos',
            distance: '153km',
            duration: '1h 56min',
            vehicleImages: vechiclesImages, // Array of car images for the carousel
            vehicles: vechicles, // Names of the cars corresponding to images
            price: '673.2 CHF',
            leftCor: '82%',
            topCor: '45%',
        },
        {
            destination: 'St. Moritz',
            distance: '208km',
            duration: '2h 44min',
            vehicleImages: vechiclesImages, // Array of car images for the carousel
            vehicles: vechicles, // Names of the cars corresponding to images
            price: '915.2 CHF',
            leftCor: '82%',
            topCor: '55.5%',
        },
        {
            destination: 'Lugano',
            distance: '223km',
            duration: '2h 34min',
            vehicleImages: vechiclesImages, // Array of car images for the carousel
            vehicles: vechicles, // Names of the cars corresponding to images
            price: '981.2 CHF',
            leftCor: '64%',
            topCor: '83%',
        },
        {
            destination: 'St. Gallen',
            distance: '85km',
            duration: '57min',
            vehicleImages: vechiclesImages, // Array of car images for the carousel
            vehicles: vechicles, // Names of the cars corresponding to images
            price: '981.2 CHF',
            leftCor: '68%',
            topCor: '27%',
        },
        {
            destination: 'Luzern',
            distance: '67,5km',
            duration: '54min',
            vehicleImages: vechiclesImages, // Array of car images for the carousel
            vehicles: vechicles, // Names of the cars corresponding to images
            price: '981.2 CHF',
            leftCor: '45%',
            topCor: '26%',
        },
        {
            destination: 'Zürich',
            distance: '11km',
            duration: '20min',
            vehicleImages: vechiclesImages, // Array of car images for the carousel
            vehicles: vechicles, // Names of the cars corresponding to images
            price: '981.2 CHF',
            leftCor: '55%',
            topCor: '15%',
        },
    ];
    const [selectedDestination, setSelectedDestination] = useState(coordinates[0]);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            offset: 100,
        });
    }, []);

    // Change carousel slide automatically every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % selectedDestination.vehicleImages.length);
        }, 9000); // 3 seconds delay

        return () => clearInterval(interval); // Clear the interval when component unmounts
    }, [selectedDestination,]);

    useEffect(() => {{
        setSelectedDestination(coordinates[0]);
    }}, [i18n,t]);



    return (
        <div className='bg-[#242424] pt-24 pb-0 lg:pb-36'>
            <div className="relative min-h-screen  text-white flex flex-col items-center justify-center">
                {/* Title */}
                <h2 className="absolute top-10 text-2xl md:text-4xl font-semibold text-center text-white uppercase" data-aos="fade-up" dangerouslySetInnerHTML={{ __html: t("destinations_you_like") }} />

                {/* Animated Background Map */}
                <div className="relative top-24 sm:top-0 md:top-18 lg:top-20 xl:top-36 3xl:top-10" data-aos="fade-up">
                    {/* The image with relative positioning */}
                    <img src={bgMap} alt="Map" className="w-full h-auto" />

                    {/* The overlay div with absolute positioning */}
                    <div className="absolute inset-0">
                        {coordinates.map((data, index) => (
                            <div
                                key={index}
                                className="absolute flex items-center group cursor-pointer"
                                style={{ left: data.leftCor, top: data.topCor }}
                                onClick={() => setSelectedDestination(data)}
                            >
                                {/* Vertical line (hr) for mobile view */}
                                <div className="relative flex items-center justify-center">
                                    <div className={`relative z-10 w-2 md:w-4 h-2 md:h-4  rounded-full before:content-[''] before:absolute before:w-full before:h-full before:bg-[#da291c] before:rounded-full before:animate-pulsate ${selectedDestination.destination === data.destination ? 'bg-white' : 'bg-[#da291c]'}`}></div>
                                    <hr className="absolute block sm:hidden border-[#da291c] w-[30px] rotate-90 mb-6 mx-auto" />
                                </div>

                                {/* Destination Label */}
                                <span className="ml-2 mb-6 md:ml-4 md:mb-8 
                            px-0 lg:px-3 sm:px-3
                             py-1  md:py-1 border-[0px] 3xl:border-[1px] sm:border-[1px] border-[#da291c] 
                            text-[8px] md:text-xs lg:text-sm sm:text-[10px] text-white uppercase font-[500]">
                                    {data.destination}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Destination Info Card */}
                {selectedDestination && (
                    <div className="absolute top-[14%] md:top-[18%] lg:top-[16%] xl:top-[22%] 3xl:top-[14%] sm:top-[15%] md:left-[1%] lg:left-[1%] xl:left-[13%] 3xl:left-[22%] sm:left-[1.5%] w-[300px] lg:w-[250px] md:w-[200px] sm:w-[200px] bg-[#1a1a1a] opacity-85 p-4 rounded-lg shadow-md z-20" data-aos="fade-up">
                        <div className='flex sm:flex-col'>
                            <div>
                                <h3 className="text-base md:text-lg font-semibold uppercase">{selectedDestination.destination}</h3>
                                <p className="text-xs md:text-sm mt-2">
                                    {t('distance_from_zurich_airport')} <br />
                                    <span className="text-[#da291c]">{selectedDestination.distance}</span>
                                </p>
                                <p className="text-xs md:text-sm mt-1">
                                    {t('approximate_duration')} <br />
                                    <span className="text-[#da291c]">{selectedDestination.duration}</span>
                                </p>
                            </div>

                            {/* Carousel for Car Images */}
                            <div className="relative">
                                {/* Car Name */}
                                <p className="text-xs md:text-sm mt-1">{selectedDestination.vehicles[currentSlide]}</p>
                                <img src={selectedDestination.vehicleImages[currentSlide]} alt="Car" className="mt-2  w-[150px] h-[80px] md:h-[130px] lg:w-full" />
                                {/* <img src={selectedDestination.vehicleImage} alt="Car" className="mt-2 w-[150px] md:w-auto lg:w-full" /> */}


                                {/* Carousel Dots */}
                                <div className="flex justify-center mt-2">
                                    {selectedDestination.vehicleImages.map((_, index) => (
                                        <span
                                            key={index}
                                            onClick={() => setCurrentSlide(index)}
                                            className={`cursor-pointer h-2 w-2 mx-1 rounded-full ${index === currentSlide ? 'bg-[#da291c]' : 'bg-gray-300'}`}
                                        ></span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Destinations;