import React, { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import peakpx from "../assets/images/peakpx.jpg";
import whatsAppLogo from "../assets/images/whatsappLogo.png";
import { useTranslation } from 'react-i18next';

const CustomWhatsAppWidget = () => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const toggleWidget = () => {
        setIsOpen(!isOpen);
    };

    const handleStartChat = () => {
        window.open('https://wa.me/+41788679093', '_blank');
    };

    return (
        <div className="fixed bottom-5 right-5 z-50">
            <div
                className="flex justify-center items-center bg-green-500 rounded-full p-3 shadow-lg cursor-pointer"
                onClick={toggleWidget}
            >
                <FaWhatsapp className="text-white text-3xl" />
            </div>
            {isOpen && (
                <div className="absolute bottom-16 right-0 w-[90vw] md:w-80 h-[300px]  rounded-lg shadow-lg bg-white overflow-hidden">
                    {/* Header */}
                    <div className="flex items-center justify-between p-3 bg-green-700 text-white">
                        <div className="flex items-center">
                            <div className='w-10 h-10 bg-white rounded-full mr-2 flex justify-center items-center'>
                                <img
                                    src={whatsAppLogo}
                                    alt="Profile"
                                    className=" w-6 h-6"
                                />
                            </div>
                            <div>
                                <h4 className="text-sm font-bold">Helvetia Limousine</h4>
                                <p className="text-xs">{t("online")}</p>
                            </div>
                        </div>
                        <button className="text-white" onClick={toggleWidget}>
                            <AiOutlineClose className="text-xl" />
                        </button>
                    </div>
                    <div
                        className="p-4 text-sm h-[150px] bg-cover bg-center relative"
                        style={{ backgroundImage: `url(${peakpx})` }}
                    >
                        <div className="bg-white p-3 rounded-lg shadow-md max-w-[80%]">
                            <p className="font-bold text-gray-700">Helvetia Limousine</p>
                            <p>{t("whatsapp_text")}</p>
                        </div>
                    </div>
                    <div className="flex justify-center items-center text-center bg-slate-100 h-[90px]">
                        <button
                            onClick={handleStartChat}
                            className="bg-green-500 w-[80%] text-white px-6 py-2 rounded-full hover:bg-green-600 transition-colors"
                        >
                            {t("chat")}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomWhatsAppWidget;
