// src/components/GoogleMap.jsx
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const GoogleMap = ({ classNames = 'w-[100%] md:[400px] h-[600px]' }) => {
  const { t, i18n } = useTranslation(); // Access the current language through i18n

  useEffect(() => {
    // Initialize the map when the component mounts
    const initMap = () => {
      // Define the coordinates for the two locations
      const locations = [
        {
          position: { lat: 47.37011637316904, lng: 8.53897272698975 },
          content: `
            <div>
              <h2 style="font-size:18px;font-weight:bold;">Helvetia Limousine</h2><br /> <span style="font-weight:bold;">
              ${t('location1')}
              </span ><br /><br />
              <strong  style="font-weight:bold;">Whatsapp:</strong></br> +41 78 867 90 93<br />
              <br />
              <strong  style="font-weight:bold;">E-mail:</strong></br> info@helvetialimousine.ch
            </div>
          `,
          title: 'Helvetia Limousine',
        },
        {
          position: { lat: 47.452677775960666, lng: 8.561470625629326 }, // Coordinates for the second location
          content: `
            <div>
              <h2 style="font-size:18px;font-weight:bold;">Helvetia Limousine</h2><br /><span style="font-weight:bold;"> ${t('location2')}
              </span ><br /><br />
              <strong  style="font-weight:bold;">Whatsapp:</strong></br> +41 78 867 90 93<br />
              <br />
              <strong  style="font-weight:bold;">E-mail:</strong></br> info@helvetialimousine.ch
            </div>
          `,
          title: 'Helvetia Limousine',
        },
      ];

      // Create a new map centered at the first location
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: locations[0].position,
        zoom: 12, // Initial zoom level
      });

      // Create a LatLngBounds object to adjust the map to show both markers
      const bounds = new window.google.maps.LatLngBounds();

      // Variable to keep track of the currently open info window
      let currentInfoWindow = null;

      // Loop through the locations and create markers for each one
      locations.forEach((location) => {
        // Create a marker for the location
        const marker = new window.google.maps.Marker({
          position: location.position,
          map,
          title: location.title,
        });

        // Create an info window for the location
        const infoWindow = new window.google.maps.InfoWindow({
          content: location.content,
        });

        // Show info window when the marker is clicked
        marker.addListener('click', () => {
          // Close the current info window if one is open
          if (currentInfoWindow) {
            currentInfoWindow.close();
          }
          // Open the new info window
          infoWindow.open(map, marker);
          // Set the current info window to the new one
          currentInfoWindow = infoWindow;
        });

        // Extend the bounds to include the location
        bounds.extend(location.position);
      });

      // Adjust the map to fit both markers
      map.fitBounds(bounds);

      // Set an initial zoom level after fitting bounds
      window.google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
        map.setZoom(map.getZoom() - 1); // Zoom out by 1 level after fitBounds
      });
    };

    // Load the Google Maps script
    const loadGoogleMapsScript = () => {
      // Remove any existing Google Maps script to avoid conflicts
      const existingScript = document.querySelector('script[src*="maps.googleapis.com"]');
      if (existingScript) existingScript.remove();

      // Create a new script tag to load Google Maps
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initMap&language=${i18n.language}`;
      script.async = true;
      document.head.appendChild(script);
      script.onload = initMap;
    };

    // Check if the Google object is available and load the script accordingly
    if (!window.google) {
      loadGoogleMapsScript();
    } else {
      initMap();
    }
  }, [t, i18n.language]); // Add i18n.language to the dependency array to reload the map on language change

  return <div id="map" className={classNames}></div>;
};

export default GoogleMap;
