import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaInstagram, FaEnvelope, FaPhone } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { HiBuildingOffice } from "react-icons/hi2";

export default function NewFooter({ staticRoutes, legalRoutePath, termRoutePath }) {
    const { t } = useTranslation();
    const handleOpenMap1 = () => {
        window.open('https://www.google.com/maps/place/Paradeplatz/@47.3696586,8.5390371,16z/data=!4m6!3m5!1s0x47900b897d578ef9:0xbf0174f3d98a4b76!8m2!3d47.3697508!4d8.538908!16s%2Fm%2F043k5vx?hl=de-ch&entry=ttu&g_ep=EgoyMDI0MDkwOC4wIKXMDSoASAFQAw%3D%3D', '_blank');
    };
    const handleOpenMap2 = () => {
        window.open('https://www.google.com/maps/place/Terminal+1,+Flughafenstrasse,+8302+Kloten,+Schweiz/@47.4525581,8.558885,17z/data=!3m1!4b1!4m6!3m5!1s0x4790755ae4880ddd:0x5d1c9db25f5668d3!8m2!3d47.4525581!4d8.5614599!16s%2Fg%2F1hc4dfqpp?hl=de-ch&entry=ttu&g_ep=EgoyMDI0MDkwOC4wIKXMDSoASAFQAw%3D%3D', '_blank');
    };
    const handleCopyNumber = () => {
        const phoneNumber = '+41 78 867 90 93';
        navigator.clipboard.writeText(phoneNumber).then(() => {
            alert('Phone number copied to clipboard!');
        });
    };

    return (
        <footer className="text-center text-lg-start my-8">
            <section className="flex flex-col md:flex-row justify-center items-center p-4">
                {/* <div className="mr-5  py-3">
                    <span>{t("get_conntected")}</span>
                </div> */}
                <div className="flex space-x-4">
                    <a
                        href="https://www.instagram.com/helvetialimousine/" // Replace with your Facebook profile URL
                        target="_blank"
                        rel="noopener noreferrer"
                        className='text-[25px] hover:text-[#da291c]'
                    >
                        <FaInstagram />

                    </a>
                </div>
            </section>

            <section className="py-10 p-10 lg:p-0 lg:py-10">
                <div className="container mx-auto w-full md:w-[70vw] lg:w-full ">
                    <div className="flex justify-center items-start lg:items-start gap-24 3xl:gap-0 flex-wrap flex-col lg:flex-row">

                        {/* navigation */}
                        <div className='flex justify-start lg:justify-center flex-1 3xl:flex-0'>
                            <div className='flex flex-col items-start'>
                                <h6 className="uppercase font-bold mb-4 text-[#da291c] text-lg tracking-[1px]">Navigation</h6>
                                <div className='flex flex-col items-center'>
                                    <ul className='flex flex-col items-start'>
                                        {staticRoutes.map(([key, path]) => (
                                            <li className='mb-2 text-white  hover:text-[#da291c] uppercase'>
                                                <Link
                                                    to={path}
                                                >
                                                    {t(key)}
                                                </Link>
                                            </li>
                                        ))}

                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* service */}
                        <div className='flex justify-start lg:justify-center 3xl:flex-0'>
                            <div className='flex flex-col items-start  '>
                                <h6 className="uppercase font-bold mb-4 text-[#da291c] text-lg tracking-[1px] ">{t("footer_services")}</h6>
                                <div className='flex flex-col items-center'>
                                    <ul className='flex flex-col items-start'>
                                        <div className='text-center'>
                                            <Link to={legalRoutePath} className="text-center mt-2  tracking-wider hover:text-[#da291c]">{t("legal_notice")}</Link> {/* Adjust the text size and margin */}
                                        </div>

                                        <div className='text-center'>
                                            <Link to={termRoutePath} className="text-center mt-2  tracking-wider hover:text-[#da291c] uppercase">{t("term")}</Link> {/* Adjust the text size and margin */}
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* contact */}
                        <div className='flex justify-start lg:justify-center flex-3 lg:flex-1 3xl:flex-0'>
                            <div className='flex flex-col items-start '>
                                <h6 className="uppercase font-bold mb-4 text-lg tracking-[1px] text-[#da291c]">{t("contact")}</h6>
                                <p className="mb-2 flex items-center justify-center gap-2 hover:text-[#da291c]">
                                    <HiBuildingOffice size={20} />
                                    <p onClick={handleOpenMap1} className="cursor-pointer">{t("location1")}</p>
                                </p>
                                <p className="mb-2 flex items-center justify-center gap-2 hover:text-[#da291c]">
                                    <HiBuildingOffice size={20} />
                                    <p onClick={handleOpenMap2} className="cursor-pointer">{t("location2")}</p>
                                </p>
                                <p className="mb-2 flex items-center justify-center gap-2 hover:text-[#da291c]">
                                    <FaEnvelope className="mr-2" />
                                    <a href="mailto:info@helvetialimousine.ch">
                                        info@helvetialimousine.ch
                                    </a>
                                </p>
                                <p className="mb-2 flex items-center justify-center gap-2 hover:text-[#da291c]">
                                    <FaPhone className="mr-2" /> <p onClick={handleCopyNumber} className='cursor-pointer' >+41 78 867 90 93</p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    );
}
