import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { IoPeople } from "react-icons/io5";
import { MdLuggage } from "react-icons/md";
import { MdFamilyRestroom } from "react-icons/md";
import { MdChildCare } from "react-icons/md";


const CustomDropDownOptions = ({ options, selected, setUpdate, dropdownName }) => {
    const [isOpen, setIsOpen] = useState(false); // State to handle dropdown open/close
    const dropdownRef = useRef(); // Reference for the dropdown element

    const handleToggle = (event) => {
        event.preventDefault();
        setIsOpen(!isOpen);
    };

    const handleSelect = (option) => {
        setUpdate(option.value);
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative w-[100%]" ref={dropdownRef}>
            <button
                type="button"
                onClick={handleToggle}
                className="w-[90vw]  md:w-full py-2 px-3 lg:px-0 xl:px-3  flex items-center justify-start gap-2 border border-gray-600 rounded-sm text-black focus:outline-none"
                aria-expanded={isOpen}
            >
                {
                    dropdownName == 'Passengers' &&
                    <IoPeople className=" text-gray-400" />
                }
                {
                    dropdownName == 'Baggage' &&
                    <MdLuggage className=" text-gray-400" />
                }
                {
                    dropdownName == 'Child' &&
                    <MdFamilyRestroom className=" text-gray-400" />
                }
                {
                    dropdownName == 'Seat' &&
                    <MdChildCare className=" text-gray-400" />
                }
                <span className="flex items-center">
                    {selected}
                </span>
                {isOpen ? <AiOutlineUp className="ml-2" /> : <AiOutlineDown className="ml-2" />}
            </button>
            {isOpen && (
                <ul className="absolute z-10  mt-1 bg-red-200 text-black border border-gray-300 rounded-sm shadow-lg w-[90vw]  md:w-[200px] lg:w-[120px] ">
                    {options.map((option) => (
                        <li
                            key={option.value}
                            onClick={() => handleSelect(option)}
                            className={`cursor-pointer px-4 py-2 flex items-center border-b-2 ${option.value === selected ? 'bg-[#da291c] text-white' : 'hover:bg-[#da291c] hover:text-white'
                                }`}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomDropDownOptions;
