import React from 'react';
import HelveticaImage from '../assets/images/helvetia.png';

// Payment method logos
import Visa from '../assets/images/icons/payment/visa.png'; // Adjust the path to the Visa logo
import VPay from '../assets/images/icons/payment/icon-v-pay.png'; // Adjust the path to the vpay logo
import MasterCard from '../assets/images/icons/payment/mastercard.png'; // Adjust the path to the MasterCard logo
import AmericanExpress from '../assets/images/icons/payment/american-express.png'; // Adjust the path to the American Express logo
import Twint from '../assets/images/icons/payment/twint.png'; // Adjust the path to the Twint logo
import Maestro from '../assets/images/icons/payment/icon-maestro.png'; // Adjust the path to the Maestro logo
import UnionPay from '../assets/images/icons/payment/icon-unionpay.png'; // Adjust the path to the UnionPay logo
import Alipay from '../assets/images/icons/payment/icon-alipay.png'; // Adjust the path to the Alipay logo
import DinersClub from '../assets/images/icons/payment/icon-diners.png'; // Adjust the path to the Diners Club logo
import Discover from '../assets/images/icons/payment/icon-discover.png'; // Adjust the path to the Discover logo
import JCB from '../assets/images/icons/payment/icon-jcb.png'; // Adjust the path to the JCB logo
import PostFinance from '../assets/images/icons/payment/icon-postfinance.png'; // Adjust the path to the PostFinance logo
import ApplePay from '../assets/images/icons/payment/icon-apple-pay.png'; // Adjust the path to the Apple Pay logo
import GooglePay from '../assets/images/icons/payment/icon-google-pay.png'; // Adjust the path to the Google Pay logo
import SamsungPay from '../assets/images/icons/payment/icon-samsung-pay.png'; // Adjust the path to the Samsung Pay logo
import {

  useTranslation
} from 'react-i18next';
import ROUTES_CONFIG from '../routesConfig';
import NewFooter from './NewFooter';


const Footer = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language; // Get the current language ('en' or 'de')
  const routes = ROUTES_CONFIG[currentLanguage];
  const staticRoutes = Object.entries(routes).filter(
    ([key]) => !['sliderDetails', 'singleBlog', 'legal_notice', "booking", 'term'].includes(key)
  );
  const legalRoute = Object.entries(routes).filter(
    ([key]) => ['legal_notice'].includes(key)
  );
  const legalRoutePath = legalRoute[0][1];

  const termRoute = Object.entries(routes).filter(
    ([key]) => ['term'].includes(key)
  );
  const termRoutePath = termRoute[0][1];

  return (
    <footer
      className="text-white pb-5 bg-[#000000]" // Flex to center content
    >
      <img
        src={HelveticaImage}
        alt="Helvetica"
        className="w-[350px] mx-auto pt-[80px] " // Tailwind classes for width and height
      />

      <NewFooter staticRoutes={staticRoutes} legalRoutePath={legalRoutePath} termRoutePath={termRoutePath} />


      <p className="text-center mt-[80px] text-[16px]  tracking-widest text-[#da291c]" // Underline, text small, and letter spacing
      >{t("payment_methods")}</p>
      {/* Payment Methods Section */}
      <div className="flex flex-wrap justify-center items-center mt-8 space-x-1 gap-3">
        <img src={Visa} alt="Visa" className="h-10 mx-2" />
        <img src={VPay} alt="Visa" className="h-10 mx-2" />
        <img src={MasterCard} alt="MasterCard" className="h-10 mx-2" />
        <img src={AmericanExpress} alt="American Express" className="h-10 mx-2" />
        <img src={Twint} alt="Twint" className="h-10 mx-2" />
        <img src={Maestro} alt="Maestro" className="h-10 mx-2" />
        <img src={UnionPay} alt="UnionPay" className="h-10 mx-2" />
        <img src={Alipay} alt="Alipay" className="h-10 mx-2" />
        <img src={DinersClub} alt="Diners Club" className="h-10 mx-2" />
        <img src={Discover} alt="Discover" className="h-10 mx-2" />
        <img src={JCB} alt="JCB" className="h-10 mx-2" />
        <img src={PostFinance} alt="PostFinance" className="h-10 mx-2" />
        <img src={ApplePay} alt="Apple Pay" className="h-10 mx-2" />
        <img src={GooglePay} alt="Google Pay" className="h-10 mx-2" />
        <img src={SamsungPay} alt="Samsung Pay" className="h-10 mx-2" />
      </div>


      <div className=" flex-col items-center justify-center mt-16 ml-auto mr-auto px-4 pt-4 w-[90vw]">
        {/* Copyright Text */}
        <p className="text-sm text-gray-400 text-center">
          {t("copyrights")}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
