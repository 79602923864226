import React from 'react';
import Banner from '../components/Banner';
import CarOursBanner from '../assets/images/banner/ourcars.jpg';
import GoogleMap from '../components/GoogleMap';
import { FaBriefcase, FaSuitcase, FaUser } from 'react-icons/fa';
import { IoIosArrowForward } from "react-icons/io";
import SEO from '../components/SEO';
import { useTranslation } from 'react-i18next';
import BusinessCar from '../assets/images/cars/business.png';
import VipCar from '../assets/images/cars/vip.png';
import VanImage from '../assets/images/cars/v.png';
import BMWImage from '../assets/images/cars/BMW.png';
import MaybachCar from '../assets/images/cars/maybach.png';
import SprinterVipCar from '../assets/images/cars/sprinter-vip.png';

const CarCard = ({ car }) => {
  return (
    <div className="flex flex-col md:flex-row items-start border-b border-gray-200 py-8">
      {/* Left Column: Car Image and Capacity */}
      <div className="w-full md:w-1/2 flex flex-col items-center justify-center space-y-4 mb-5 md:mb-0">
        {/* Car Image */}
        <div className="relative mb-4">
          <img src={car.image} alt={car.model} />
        </div>

        {/* Capacity Box */}
        <div className="flex items-center space-x-4 bg-[#f6f1f1] p-3 rounded-md border border-gray-200">
          <div className="flex items-center space-x-1 text-black">
            <FaUser className="text-lg text-[#da291c]" />
            <span className="text-sm font-medium">x {car.capacity.passengers}</span>
          </div>
          <div className="flex items-center space-x-1 text-black">
            <FaSuitcase className="text-lg text-[#da291c]" />
            <span className="text-sm font-medium">x {car.capacity.luggage}</span>
          </div>
          <div className="flex items-center space-x-1 text-black">
            <FaBriefcase className="text-lg text-[#da291c]" />
            <span className="text-sm font-medium">x {car.capacity.handLuggage}</span>
          </div>
        </div>
      </div>

      {/* Right Column: Car Details */}
      <div className="w-full md:w-1/2 px-6 flex flex-col space-y-4">
        <h3 className="text-3xl font-normal text-black uppercase tracking-widest">{car.category}</h3>
        <h4 className="text-lg font-semibold text-[#da291c]">{car.model}</h4>
        <p className="text-sm text-black">{car.description}</p>

        {/* Car Features */}
        <ul className="list-none mt-4 space-y-2 text-black text-sm">
          {car.features.map((feature, index) => (
            <li key={index} className="flex items-start">
              <span className="mr-2"><IoIosArrowForward className='text-[#da291c]' /></span> {/* Custom bullet point */}
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const OurCars = () => {
  const { t } = useTranslation();

  // Fetch translated car details
  const cars = [
    {
      image: BusinessCar, // Replace with actual image for Business Klasse
      category: t('carsDetails.business_klasse.category'),
      model: t('carsDetails.business_klasse.model'),
      description: t('carsDetails.business_klasse.description'),
      capacity: {
        passengers: 4,
        luggage: 3,
        handLuggage: 3,
      },
      features: t('carsDetails.business_klasse.features', { returnObjects: true }),
    },
    {
      image: VipCar, // Replace with actual image for VIP Klasse
      category: t('carsDetails.vip_klasse.category'),
      model: t('carsDetails.vip_klasse.model'),
      description: t('carsDetails.vip_klasse.description'),
      capacity: {
        passengers: 3,
        luggage: 3,
        handLuggage: 2,
      },
      features: t('carsDetails.vip_klasse.features', { returnObjects: true }),
    },
    //v class
    {
      image: VanImage, // Replace with the actual image for Van Class
      category: t('carsDetails.van_klasse.category'),
      model: t('carsDetails.van_klasse.model'),
      description: t('carsDetails.van_klasse.description'),
      capacity: {
        passengers: 8,
        luggage: 4,
        handLuggage: 6,
      },
      features: t('carsDetails.van_klasse.features', { returnObjects: true }),
    },

    {
      image: BMWImage, // Replace with the actual image for BMW X5
      category: t('carsDetails.suv_bmw_x5.category'),
      model: t('carsDetails.suv_bmw_x5.model'),
      description: t('carsDetails.suv_bmw_x5.description'),
      capacity: {
        passengers: 5,
        luggage: 5,
        handLuggage: 4,
      },
      features: t('carsDetails.suv_bmw_x5.features', { returnObjects: true }),
    },


    // bmw

    {
      image: MaybachCar, // Replace with actual image for Maybach Klasse
      category: t('carsDetails.maybach_klasse.category'),
      model: t('carsDetails.maybach_klasse.model'),
      description: t('carsDetails.maybach_klasse.description'),
      capacity: {
        passengers: 3,
        luggage: 3,
        handLuggage: 2,
      },
      features: t('carsDetails.maybach_klasse.features', { returnObjects: true }),
    },
    {
      image: SprinterVipCar, // Replace with actual image for Sprinter VIP Klasse
      category: t('carsDetails.sprinter_vip_klasse.category'),
      model: t('carsDetails.sprinter_vip_klasse.model'),
      description: t('carsDetails.sprinter_vip_klasse.description'),
      capacity: {
        passengers: 16,
        luggage: 16,
        handLuggage: 16,
      },
      features: t('carsDetails.sprinter_vip_klasse.features', { returnObjects: true }),
    },

  ];

  return (
    <>
      <SEO title={`${t('cars')} | Helvetia Limousine`} />
      <div className="w-full mt-[-100px]">
        <Banner title={t('cars')} imageURL={CarOursBanner} />
        <div className="bg-white md:bg-custom-gradient flex justify-center pt-[100px] pb-[100px] md:pb-[200px]">
          <div className="flex flex-col md:flex-row justify-between w-[90vw] xl:w-[70vw] 3xl:w-[60vw] items-start gap-8">
            {/* First Div: 65% Width */}
            <div className="w-full md:w-[60%] lg:w-[65%] pr-0 lg:pr-6 pl-0 space-y-16">
              <h1 className='font-bold text-3xl'>{t('ourCars_title')}</h1>
              <p className='leading-relaxed tracking-wide' dangerouslySetInnerHTML={{ __html: t('ourCars_description') }} />

              {/* Car list */}
              {cars.map((car, index) => (
                <CarCard key={index} car={car} />
              ))}
            </div>

            {/* Second Div: 25% Width */}
            <div className="w-full md:w-[40%] lg:w-[30%] text-black xl:mr-20">
              {/* Map Container */}
              <GoogleMap />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurCars;
