import React from 'react';
import Slider from '../components/Slider';
import Fleet from '../components/Fleet';
import LeadingSlider from '../components/LeadingSlider';
import OurServices from '../components/OurServices';
import Faq from '../components/common-layout/Faq';
import Review from '../components/Review';
import Count from '../components/Count';
import IncludedPrice from '../components/IncludedPrice';
import Destinations from '../components/Destinations';
import SEO from '../components/SEO';
import MostPopulerRoutes from '../components/MostPopulerRoutes';
import GoogleMap from '../components/GoogleMap';


const Home = () => {
  return (
    <>
      <SEO title={"Helvetia Limousine"}>
        <Slider />
        <Fleet />
        <LeadingSlider />
        <OurServices />
        <Destinations />
        {/* <IncludedPrice /> */}
        <MostPopulerRoutes />
        <Count />
        <Faq from={'home'} />
        <Review />
        <GoogleMap />
      </SEO>
    </>
  );
};

export default Home;
