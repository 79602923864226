// src/routesConfig.js

const ROUTES_CONFIG = {
    en: {
      home: '/',
      about: '/about-us',
      cars: '/our-cars',
      blog: '/blog',
      faq: '/faq',
      contact: '/contact',
      sliderDetails: '/service/:uniqueKey',
      singleBlog: '/blog/:uniqueKey',
      legal_notice: "/legal-notice",
      booking: '/booking',
      term: "/tos"
    },
    de: {
      home: '/',
      about: '/uber-uns',
      cars: '/unsere-autos',
      blog: '/blog',
      faq: '/faq',
      contact: '/kontakt',
      sliderDetails: '/Dienstleistung/:uniqueKey',
      singleBlog: '/blog/:uniqueKey',
      legal_notice: "/impressum",
      booking: '/buchung',
      term: "/agb"
    },
  };
  
  export default ROUTES_CONFIG;
  