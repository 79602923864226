import React from 'react';
import FleetSingleCard from './FleetSingleCard';
import { useTranslation } from 'react-i18next';
import carImg1 from '../assets/images/fleetCars/m11.png'
import carImg2 from '../assets/images/fleetCars/m22.png'
import carImg3 from '../assets/images/fleetCars/m33.png'
import carImg4 from '../assets/images/fleetCars/m44.png'
import carImg5 from '../assets/images/fleetCars/m55.png'
import carImg6 from '../assets/images/fleetCars/m66.png'
import MercdedesLogo from '../assets/images/fleetCars/mercedesLogo.png'
import BMWLogo from '../assets/images/fleetCars/bmwLogo.png'




const Fleet = () => {
    const { t } = useTranslation();
    const data = [
        { brandName: `Mercedes Benz ${t("e_class")}`, logo: MercdedesLogo, class: `${t("business_class")}`, chf: '4.4', img: carImg1 },
        { brandName: `Mercedes Benz ${t("s_class")}`, logo: MercdedesLogo,  class: `${t("vip_class")}`, chf: '4.8', img: carImg2 },
        { brandName: `Mercedes Benz V 250 4x4`, logo: MercdedesLogo, class: `${t("van_class")}`, chf: '4.4', img: carImg5 },
        { brandName: `SUV BMW X5`,logo: BMWLogo,  class: `SUV BMW X5`, chf: '4.4', img: carImg6 },
        { brandName: 'Mercedes Benz Maybach',logo: MercdedesLogo,  class: 'Maybach', chf: '6.4', img: carImg3 },
        { brandName: 'Mercedes Benz Sprinter',logo: MercdedesLogo,  class: `${t("sprinter_vip_class")}`, chf: t('vary'), img: carImg4 },
    ];
    return (
        <div className='relative flex flex-col items-center min-h-[80vh] 3xl:min-h-[55vh] mb-10 xl:mb-10 3xl:mb-[100px]  '>
            {/* Background Text */}
            {/* <span
                className='hidden xl:block absolute text-[100px] lg:text-[180px] xl:text-[230px] tracking-[15px] text-gray-200 font-[900] uppercase z-0 md:top-[150px] '
            >
                HELVETIA
            </span>
            <span
                className='hidden xl:block absolute text-[100px] lg:text-[180px] xl:text-[190px] tracking-[15px] text-gray-200 font-[900] uppercase z-0 md:top-[600px] '
            >
                LIMOUSINE
            </span> */}

            {/* Main Title */}
            <span className='relative text-[45px] tracking-[3px] font-[600] z-10 mt-20 text-center' dangerouslySetInnerHTML={{ __html: t("our_fleet") }} />

            {/* Fleet Cards */}
            <div className='relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 xl:gap-[150px] mt-20 z-10'>
                {data.map((car, index) => (
                    <FleetSingleCard car={car} key={index} />
                ))}
            </div>
        </div>
    );
};

export default Fleet;
