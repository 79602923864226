import React from 'react'

const Banner = ({ title, imageURL }) => {
    return (
        <div
            className='w-full h-[450px] bg-cover bg-center mt-[-100px]'
            style={{
                backgroundImage: `url("${imageURL}")`,
            }}
        >
            <div className="flex flex-col items-center justify-center h-full text-white bg-black bg-opacity-50 pt-20 p-10">
                <h1 className="text-xl md:text-6xl font-semibold uppercase tracking-[15px] text-center">{title}</h1>
            </div>
        </div>
    )
}

export default Banner