import React from 'react';
import { Helmet } from 'react-helmet';
import HelvetiaLogo from "../assets/images/helvetia.png";

const SEO = ({ title, children }) => {
  return (
    <>
      <Helmet>
        {/* Dynamic SEO tags */}
        <title>{title}</title>
        <meta name="description" content={"Discover Helvetia Limousine, Zurich's premier chauffeur service offering luxury transportation for business, events, and airport transfers. Enjoy first-class comfort, safety, and professionalism with our diverse fleet and exceptional customer care. Book your ride today!"} />
        {/* Open Graph tags for social sharing */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={"Discover Helvetia Limousine, Zurich's premier chauffeur service offering luxury transportation for business, events, and airport transfers. Enjoy first-class comfort, safety, and professionalism with our diverse fleet and exceptional customer care. Book your ride today!"} />
        <meta property="og:image" content={HelvetiaLogo} />
        <meta property="og:url" content={'https://helvetialimousine.ch'} />
      </Helmet>
      {children}
    </>
  );
};

export default SEO;
