import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

const SingleService = ({ service, index, animation= false }) => {
    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({
            offset: 100,
        });
    }, []);

    return (
        <div
            className='flex flex-col justify-center items-start gap-6 w-[80vw] 3xl:w-[18vw] lg:w-[22vw] md:w-[40vw] sm:w-[40vw]'
            data-aos='fade-right'
            data-aos-duration={(index + 1) * 250}
        >
            <div className='h-[70px] text-[50px] text-[#da291c]'>
                {service.image}
            </div>
            {/* <img
                className='h-[70px]'
                src={service.image}
                alt={service.title}
                style={{ filter: 'brightness(0) saturate(100%) invert(23%) sepia(35%) saturate(4007%)   contrast(200%)' }} 
            /> */}
            <h3 className='font-bold text-[22px]'>{t(`${service.title}`)}</h3>
            <p className='text-[16px] text-[#555555] tracking-[1px] font-[300] ' dangerouslySetInnerHTML={{ __html: t(`${service.description}`) }}/>
        </div>
    );
};

export default SingleService;
