import blog1Image from "../assets/images/blog/blog1.png";
import blog2Image from "../assets/images/blog/blog2.jpg";
import blog3Image from "../assets/images/blog/blog3.jpg";
import leadingImage1 from '../assets/images/leadingService/leading1.jpg'
import leadingImage2 from '../assets/images/leadingService/leading2.jpg'
import leadingImage3 from '../assets/images/leadingService/leading3.jpg'

import car1 from '../assets/images/cars/business.png'
import car2 from '../assets/images/cars/vip.png'
import car3 from '../assets/images/cars/v.png'
import car4 from '../assets/images/cars/BMW.png'
import car5 from '../assets/images/cars/maybach.png'
import car6 from '../assets/images/cars/sprinter-vip.png'


const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-GB', options).format(date).replace(' ', '. ').replace(',', '') + '.'; // Adds the dot at the end
};


export const blogs = [
  {
    uniqueKey: "helvetica-1",
    image: blog1Image,
    title: "blog1Title",
    date: formatDate("2024-08-01"),
    contents: [
      {
        title: "blog1Content1Title",
      },
      {
        title: "blog1Content2Title",
      },
      {
        title: "blog1Content3Title",
      },
      {
        title: "blog1Content4Title",
      },
      {
        title: "blog1Content5Title",
      },
      {
        title: "blog1Content6Title",
      }
    ]
  },
  {
    uniqueKey: "helvetica-2",
    image: blog2Image,
    title: "blog2Title",
    date: formatDate("2024-08-08"),
    contents: [
      {
        title: "blog2Content1Title",
      },
      {
        title: "blog2Content2Title",
      },
      {
        title: "blog2Content3Title",
      },
      {
        title: "blog2Content4Title",
      },
      {
        title: "blog2Content5Title",
      },
      {
        title: "blog2Content6Title",
      }
    ]
  },
  {
    uniqueKey: "helvetica-3",
    image: blog3Image,
    title: "blog3Title",
    date: formatDate('2024-08-16'),
    contents: [
      {
        title: "blog3Content1Title",
      },
      {
        title: "blog3Content2Title",
      },
      {
        title: "blog3Content3Title",
      },
      {
        title: "blog3Content4Title",
      },
      {
        title: "blog3Content5Title",
      },
      {
        title: "blog3Content6Title",
      },
    ]
  }

]

export const services = [
  {
    title: "landing1Title",
    uniqueKey: "helvetica-service-1",
    image: leadingImage1,
    introduction: "landing1Introduction",
    description: [
      "landing1Description1",
      "landing1Description2",
    ]
  },
  {
    title: "landing2Title",
    uniqueKey: "helvetica-service-2",
    image: leadingImage2,
    introduction: "landing2Introduction",
    description: [
      "landing2Description1",
      "landing2Description2",
      "landing2Description3",
    ]
  },
  {
    title: "landing3Title",
    uniqueKey: "helvetica-service-3",
    image: leadingImage3,
    introduction: "landing3Introduction",
    description: [
      "landing3Description1",
      "landing3Description2",
      "landing3Description3",
    ]
  }
];

export const mostPopularRoutes = [
  {
    from: "Zürich",
    to: "St. Gallen",
    duration: "57min",
    distance: "85 Km"
  },
  {
    from: "Zürich",
    to: "Luzern",
    duration: "54min",
    distance: "67.5 Km"
  },
  {
    from: "Zürich",
    to: "Bern",
    duration: "1h 26min",
    distance: "126 Km"
  },
  {
    from: "Zürich",
    to: "Basel",
    duration: "1h 5min",
    distance: "92 Km"
  },
  {
    from: "Zürich",
    to: "Genf",
    duration: "3h 3min",
    distance: "284 Km"
  },
  {
    from: "Zürich",
    to: "Davos",
    duration: "1h 56min",
    distance: "153 Km"
  },
  {
    from: "Zürich",
    to: "St. Moritz",
    duration: "2h 44min",
    distance: "208 Km"
  },
  {
    from: "Zürich",
    to: "Andermatt",
    duration: "1h 32min",
    distance: "125 Km"
  },
  {
    from: "Zürich",
    to: "Lugano",
    duration: "2h 34min",
    distance: "223 Km"
  },
  {
    from: "Zürich",
    to: "Wien",
    duration: "7h 25min",
    distance: "740 Km"
  },
  {
    from: "Zürich",
    to: "Paris",
    duration: "6h 21min",
    distance: "661 Km"
  },
  {
    from: "Zürich",
    to: "London",
    duration: "10h 22min",
    distance: "1013 Km"
  },
  {
    from: "Zürich",
    to: "Barcelona",
    duration: "10h 3min",
    distance: "1065 Km"
  },
  {
    from: "Zürich",
    to: "Mailand",
    duration: "3h 52min",
    distance: "295 Km"
  },
  {
    from: "Zürich",
    to: "Berlin",
    duration: "8h 6min",
    distance: "851 Km"
  },
  {
    from: "Zürich",
    to: "Amsterdam",
    duration: "7h 45min",
    distance: "825 Km"
  }
]

export const bookingCarsDetails = [
  {
    image: car1,
    category: "bookDetCategory1",
    model: "bookDetModel1",
    price: 4.4,
    minCHF: 110,
    capacity: {
      passengers: 4,
      luggage: 3,
      handLuggage: 3,
    },
    features: [
      "bookingFeature1",
      "bookingFeature2",
      "bookingFeature3",
      "bookingFeature4"
    ]
  },
  {
    image: car2,
    category: "bookDetCategory2",
    model: "bookDetModel2",
    price: 4.8,
    minCHF: 130,
    capacity: {
      passengers: 3,
      luggage: 3,
      handLuggage: 2,
    },
    features: [
      "bookingFeature1",
      "bookingFeature2",
      "bookingFeature3",
      "bookingFeature4"
    ]
  },
  {
    image: car3,
    category: "bookDetCategory3",
    model: "bookDetModel3",
    price: 4.4,
    minCHF: 130,
    capacity: {
      passengers: 8,
      luggage: 4,
      handLuggage: 6,
    },
    features: [
      "bookingFeature1",
      "bookingFeature2",
      "bookingFeature3",
      "bookingFeature4"
    ]
  },
  {
    image: car4,
    category: "bookDetCategory4",
    model: "bookDetModel4",
    price: 4.4,
    minCHF: 110,
    capacity: {
      passengers: 5,
      luggage: 5,
      handLuggage: 4,
    },
    features: [
      "bookingFeature1",
      "bookingFeature2",
      "bookingFeature3",
      "bookingFeature4"
    ]
  },
  {
    image: car5,
    category: "bookDetCategory5",
    model: "bookDetModel5",
    price: 6.4,
    minCHF: 170,
    capacity: {
      passengers: 3,
      luggage: 3,
      handLuggage: 2,
    },
    features: [
      "bookingFeature1",
      "bookingFeature2",
      "bookingFeature3",
      "bookingFeature4"
    ]
  },
  {
    image: car6,
    category: "bookDetCategory6",
    model: "bookDetModel6",
    price: 0,
    minCHF: 0,
    capacity: {
      passengers: 16,
      luggage: 16,
      handLuggage: 16,
    },
    features: [
      "bookingFeature1",
      "bookingFeature2",
      "bookingFeature3",
      "bookingFeature4"
    ]
  },
]





