import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCaretRight } from "react-icons/fa6";
import BookingNav from '../components/BookingNav';
import { useTranslation } from 'react-i18next';
import GoogleMapDirection from '../components/GoogleMapDirection';
import { bookingCarsDetails } from '../utils/helper';
import Vehicle from '../components/Booking/Vehicle';
import PassengerDetailsForm from '../components/Booking/PassengerDetailsForm';
import BookingPayment from '../components/Booking/BookingPayment';
import FinishPayment from '../components/Booking/FinishPayment';


const Booking = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // State to track the current step
  const [currentStep, setCurrentStep] = useState(0);
  const [bookingData, setBookingData] = useState(null);
  const [isBookingPerKm, setIsBookingPerKm] = useState(false);
  const [isMapVisible, setIsMapVisible] = useState(true); // State to control map visibility
  const [isLargeDevice, setIsLargeDevice] = useState(window.innerWidth >= 1024); // State to track if it's a large device
  const [bookingDistance, setBookingDistance] = useState(null);
  const [chosenCar, setChoseCar] = useState({});
  const [passengerDetails, setPassengerDetails] = useState({})
  const [paymentSystem, setPaymentSystem] = useState('credit_card');
  const [totalPrice, setTotalPrice] = useState(0)
  const [appDuration, setAppDuration] = useState('');

  // Define the steps for the progress indicator
  const steps = [
    `${t("booking_vehicles")}`,
    `${t("booking_passengers")}`,
    `${t("booking_payment")}`,
    `${t("booking_finished")}`
  ];

  useEffect(() => {
    // Check if 'bookingData' exists in sessionStorage
    const bookingData = JSON.parse(sessionStorage.getItem('bookingData'));

    if (!bookingData) {
      navigate('/');
    } else {
      // If booking data exists, extract relevant locations
      if (bookingData.pickupLocation && bookingData.dropoffLocation) {
        setBookingData(bookingData);
        setIsBookingPerKm(true);
      } else if (bookingData.startLocation) {
        setBookingData(bookingData);
        setIsBookingPerKm(false);
      }
    }

    // Function to handle window resize
    const handleResize = () => {
      setIsLargeDevice(window.innerWidth >= 1024);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [navigate]); // Ensure the effect runs on navigate changes

  // Function to handle the next step
  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  // Function to handle the previous step
  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Function to handle the toggle button click
  const handleToggle = () => {
    setIsMapVisible(!isMapVisible);
  };
  return (
    <div className='min-h-screen flex flex-col'>
      <BookingNav />
      <div className="flex flex-col items-center mt-10">
        {/* Step Progress Indicator */}
        <div className="flex space-x-2 sm:space-x-4 mb-4">
          {steps.map((step, index) => (
            <div key={index} className="flex items-center">
              <span
                className={`min-w-[50px] md:min-w-[100px] text-[10px] sm:text-[14px] xs:text-[12px] text-center uppercase ${index <= currentStep ? 'font-bold text-black' : 'text-gray-400'
                  }`}
              >
                {step}
              </span>
              {index < steps.length - 1 && (
                <FaCaretRight className="mx-1 sm:mx-2 text-gray-400" />
              )}
            </div>
          ))}
        </div>

        {/* Toggle Button - visible on tablets, hidden on larger screens */}
        <div className='flex justify-end w-[95vw] items-center'>
          <label className="inline-flex items-center cursor-pointer lg:hidden">
            <input type="checkbox" value="" className="sr-only peer" checked={isMapVisible} onChange={handleToggle} />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white peer-checked:after:bg-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#da291c] after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#da291c]"></div>
            <span className="ms-3 text-sm font-medium text-gray-500">{t("your_ride")}</span>
          </label>
        </div>

        {/* Map */}
        <div className='w-[95vw] md:w-[90vw] lg:w-[60vw] flex flex-col lg:flex-row items-start gap-4 lg:gap-0 justify-between mt-4'>
          {((isMapVisible || isLargeDevice) && currentStep != 3) && (
            <div className='w-full lg:w-[40%] bg-[#da291c]'>
              <h1 className={`mt-10 ${isBookingPerKm ? 'mb-10' : 'mb-2'}  text-white ml-5 text-xl`}>{t("your_ride")}</h1>
              <GoogleMapDirection
                bookingData={bookingData}
                setBookingDistance={setBookingDistance}
                chosenCar={chosenCar}
                passengerDetails={passengerDetails}
                setAppDuration={setAppDuration}
              />
            </div>
          )}

          {/* Form */}
          <div className={`w-[100%] ${currentStep != 3 ? 'lg:w-[55%]' : 'lg:w-[80%]'}  mx-auto`}>

            {/* 1st Step Vehicle */}
            {currentStep == 0 &&
              <div className='w-full lg:border-l-2 border-red-100 pl-0 md:pl-10 flex flex-col gap-10'>
                {bookingCarsDetails.map((car, index) => (
                  <Vehicle key={index} car={car} setChoseCar={setChoseCar} bookingDistance={bookingDistance} nextStep={nextStep} setTotalPrice={setTotalPrice} bookingData={bookingData} />
                ))}
              </div>
            }

            {/* 2nd Step Form */}
            {currentStep == 1 &&
              <div className='w-full lg:border-l-2 border-red-100 pl-0 md:pl-4 flex flex-col gap-10'>
                <PassengerDetailsForm chosenCar={chosenCar} nextStep={nextStep} setPassengerDetails={setPassengerDetails} />
              </div>
            }
            {/* 3rd Step payment */}
            {currentStep == 2 &&
              <div className='w-full lg:border-l-2 border-red-100 pl-0 md:pl-4 flex flex-col gap-10'>
                <BookingPayment nextStep={nextStep} setPaymentSystem={setPaymentSystem} />
              </div>
            }

            {currentStep == 3 &&
              <div className='w-full flex flex-col gap-10'>
                <FinishPayment
                  bookingData={bookingData}
                  chosenCar={chosenCar}
                  passengerDetails={passengerDetails}
                  paymentSystem={paymentSystem}
                  totalPrice={totalPrice}
                  appDuration={appDuration}
                />
              </div>
            }

          </div>
        </div>
      </div>



      {/* footer */}
      <footer className="mt-10 mb-4 text-center text-sm text-gray-500 font-[400]">

        {t('copyright_notice')}
      </footer>
    </div>
  );
};

export default Booking;
