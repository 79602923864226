// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import CustomWhatsAppWidget from './components/CustomWhatsAppWidget';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import OurCars from './pages/OurCars';
import Blog from './pages/Blog';
import FAQ from './pages/FAQ';
import SliderDetails from './pages/SliderDetails';
import ScrollToTop from './components/ScrollToTop';
import SingleBlog from './pages/SingleBlog';
import ROUTES_CONFIG from './routesConfig';
import { useTranslation } from 'react-i18next';
import Legal from './pages/Legal';
import { LoadScript } from "@react-google-maps/api";
import Booking from './pages/Booking';
import Term from './pages/Term';

const libraries = ["places"]; // Load the 'places' library for Places Autocomplete

function App() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language; // Get the current language ('en' or 'de')
  const routes = ROUTES_CONFIG[currentLanguage] || ROUTES_CONFIG.en; // Default to English routes

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries} loadingElement={<></>}>
      <Router>
        <ScrollToTop />
        <AppLayout routes={routes} />
      </Router>
    </LoadScript>
  );
}

// Separate component for the main layout
function AppLayout({ routes }) {
  const location = useLocation(); // Now we can use useLocation safely inside the Router context

  // Determine whether to show Navbar and Footer based on the current route
  const hideNavAndFooter = location.pathname === routes.booking;

  return (
    <div className="app-container">
      {!hideNavAndFooter && <Navbar />}
      <div className="content">
        <Routes>
          <Route path={routes.home} element={<Home />} />
          <Route path={routes.about} element={<About />} />
          <Route path={routes.cars} element={<OurCars />} />
          <Route path={routes.blog} element={<Blog />} />
          <Route path={routes.faq} element={<FAQ />} />
          <Route path={routes.contact} element={<Contact />} />
          <Route path={routes.legal_notice} element={<Legal />} />
          <Route path={routes.booking} element={<Booking />} />
          <Route path={routes.term} element={<Term />} />
          {/* Dynamic route for slider paths */}
          <Route path={routes.sliderDetails} element={<SliderDetails />} />
          <Route path={routes.singleBlog} element={<SingleBlog />} />
          <Route path="*" element={<Navigate to={routes.home} />} />
        </Routes>
      </div>
      {!hideNavAndFooter && <Footer />}
      {!hideNavAndFooter && <CustomWhatsAppWidget />}
    </div>
  );
}

export default App;
