import React from 'react';
import Banner from '../components/Banner';
import AboutBanner from '../assets/images/banner/about.jpg'
import ContactInformation from '../components/common-layout/contactInformation';
import AboutImage1 from '../assets/images/about/about1.jpg'
import AboutImage2 from '../assets/images/about/about2.jpg';
import GermanyFlag from "../assets/images/about/german.png"
import AmericanFlag from "../assets/images/about/american.png"
import SaudiFlag from "../assets/images/about/saudi.png"
import RussianFlag from "../assets/images/about/russian.png"
import { GoDotFill } from "react-icons/go";
import SEO from '../components/SEO';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <SEO title={`${t("about")} | Helvetia Limousine`} >
        <Banner title={t("about")} imageURL={AboutBanner} />
        <div className="bg-white md:bg-custom-gradient flex justify-center pt-[100px] pb-[100px] md:pb-[200px]">
          <div className="flex flex-col md:flex-row justify-between w-[90vw] xl:w-[70vw] 3xl:w-[55vw] items-start gap-8 ">
            {/* First Div: 65% Width */}
            <div className="w-full md:w-[60%] lg:w-[65%] pr-0 lg:pr-6 pl-0 lg:pl-6">
              {/* Content for the first div */}
              <h1 className="text-[35px] font-semibold mb-4">{t("about")}</h1>
              <div className='space-y-6 mb-16'>
                <p className='text-[20px] font-semibold tracking-wider'>{t("aboutTitle1")}</p>
                <p className="tracking-wider " dangerouslySetInnerHTML={{ __html: t("aboutDescription1") }}/>
              </div>
              <img src={AboutImage1} alt="AboutLogo" className='w-[80vw]  xl:w-[26vw]' />
              <div className='space-y-6 mb-16 mt-10'>
                <p className="tracking-wider  font-bold">{t("image1Alt")}</p>
                <p className="tracking-wider ">{t("image1Description")}</p>
              </div>
              <div className='space-y-6 mb-16 mt-10'>
                <p className='text-[20px] font-semibold tracking-wider'>{t("aboutTitle2")}</p>
                <p className="tracking-wider " dangerouslySetInnerHTML={{ __html: t("aboutDescription2") }}/>
              </div>
              <div className='space-y-6 mb-10'>
                <p className='text-[20px] font-semibold tracking-wider'>{t("aboutTitle3")}</p>
                <p className="tracking-wider " dangerouslySetInnerHTML={{ __html: t("aboutDescription3") }}/>
              </div>
              <div className="space-y-4  ml-5">
                <div className="flex items-start">
                  <GoDotFill className="text-[#da291c] mt-1 mr-2" />
                  <p className="tracking-wider ">{t("aboutList1")}</p>
                </div>
                <div className="flex items-start">
                  <GoDotFill className="text-[#da291c] mt-1 mr-2" />
                  <p className="tracking-wider ">{t("aboutList2")}</p>
                </div>
                <div className="flex items-start">
                  <GoDotFill className="text-[#da291c] mt-1 mr-2" />
                  <p className="tracking-wider">{t("aboutList2")}</p>
                </div>
              </div>
              <div className='space-y-6 mt-6 '>
                <p className="tracking-wider " dangerouslySetInnerHTML={{ __html: t("aboutSubDescription3") }}/>
              </div>
              <h1 className="text-[30px] font-semibold mt-10">{t("we_speak")}</h1>
              <div className="flex space-x-4 mt-6 mb-10">
                {/* German Flag */}
                <img src={GermanyFlag} alt="German Flag" className="w-[60px] h-[40px] border-[0.1px] border-gray-600" />
                {/* American Flag */}
                <img src={AmericanFlag} alt="American Flag" className="w-[60px] h-[40px] border-[0.1px] border-gray-600" />
                {/* Saudi Flag */}
                <img src={SaudiFlag} alt="Saudi Flag" className="w-[60px] h-[40px] border-[0.1px] border-gray-600" />
                {/* Russian Flag */}
                <img src={RussianFlag} alt="Russian Flag" className="w-[60px] h-[40px] border-[0.1px] border-gray-600" />
              </div>
              <img src={AboutImage2} alt="AboutLogo" className='w-[100vw]' />

            </div>

            {/* Second Div: 25% Width */}
            <ContactInformation />
          </div>
        </div>
      </SEO>
    </>
  );
};

export default About;
