import React, { useState, useEffect } from "react";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import slider1 from "../assets/images/caro/caro1.jpg";
import slider2 from "../assets/images/caro/caro2.png";
import slider3 from "../assets/images/caro/caro3.png";
import slider4 from "../assets/images/caro/caro4.png";
import slider5 from "../assets/images/caro/caro5.jpg";
import slider6 from "../assets/images/caro/caro6.jpg";
import slider7 from "../assets/images/caro/caro7.jpg";
import slider8 from "../assets/images/caro/caro8.jpg";
import slider9 from "../assets/images/caro/caro9.jpg";
import slider10 from "../assets/images/caro/caro10.jpg";
import SliderForm from "./SliderForm";
import Logo from '../assets/images/helvetia.png';
import { useTranslation } from "react-i18next";

const Slider = () => {
    const slides = [slider1, slider2, slider5, slider7, slider8, slider9, slider10];
    const [currentSlide, setCurrentSlide] = useState(0);
    const { i18n, t } = useTranslation();
    const currentLanguage = i18n.language;

    const handleLanguageChange = (lng) => {
        i18n.changeLanguage(lng); // Change language using i18n
        localStorage.setItem('language', lng); // Save selected language to localStorage
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 7000);
        return () => clearInterval(interval);
    }, [slides.length]);

    return (
        <div className="relative w-full h-[100vh] lg:h-[90vh] md:h-[90vh] sm:h-[90vh] overflow-hidden lg:mt-[-100px]">
            {slides.map((slide, index) => (
                <div
                    key={index}
                    className={`absolute inset-0 transition-opacity duration-[2000ms] ease-in-out ${index === currentSlide ? "opacity-100" : "opacity-0"}`}
                    style={{
                        backgroundImage: `url(${slide})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
            ))}

            {/* Left Side */}
            <div className="absolute lg:w-[180px] md:w-[100px] w-[0px] h-[90vh]  lg:bg-black bg-transparent flex flex-col items-start justify-between z-30">
                <div className='3xl:w-[25vh] lg:w-[35vh] md:w-[25vh] sm:w-[20vh] 3xl:h-[25vh] lg:h-[28vh] md:h-[22vh] sm:h-[18vh] w-[30vh] h-[22vh] bg-black hidden lg:flex flex-col justify-center items-center'>
                    <img className="lg:w-[150px] md:w-[120px] sm:w-[100px] w-[150px]" src={Logo} alt="Helvetia Limousine" />

                    {/* Language Selection Box */}
                    <div className="relative mt-4 lg:mt-4 md:mt-2 sm:mt-1 flex flex-col items-center justify-center">
                        <div className="relative text-white text-[14px] uppercase px-6 py-2 md:py-0 flex items-center justify-center ">
                            <select
                                value={currentLanguage}
                                onChange={(e) => handleLanguageChange(e.target.value)}
                                className="w-[80px] text-center bg-black hover:bg-[#da291c] text-white border border-[#7d7c7c] appearance-none cursor-pointer outline-none z-10 p-0 md:p-2 lg:p-2"
                            >
                                <option value="en">English</option>
                                <option value="de">Deutsch</option>

                            </select>
                        </div>
                    </div>
                </div>

                {/* Social Part */}
                <div className='hidden lg:w-[180px] lg:flex h-[45vh] flex-col items-center justify-around mb-8'>
                    <p className="text-white rotate-90 tracking-[5px] text-[12px] whitespace-nowrap">{`Helvetia ${t("service")}`}</p>
                    <hr className="h-[50px] w-[1px] mt-20 border-t-0 bg-[#7d7c7c]" />
                    <a href="https://www.instagram.com/helvetialimousine/" target="_blank" rel="noopener noreferrer" className="flex flex-col gap-5 justify-center items-center">
                        <FaInstagram size={25} className="text-[#7d7c7c] hover:text-white cursor-pointer" />
                    </a>
                </div>
            </div>

            {/* Right Slider Form */}
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-end text-white">
                <div className="lg:w-[60vw] w-full">
                    <SliderForm />
                </div>
            </div>
        </div>
    );
};

export default Slider;
