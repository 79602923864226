import React, { useEffect, useState } from 'react';
import SingleReview from './SingleReview';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import { BiPencil } from 'react-icons/bi';

const reviews = [
    {
        name: 'reviewName1',
        location: 'reviewLocation1',
        rating: 5,
        comment: 'reviewComment1',
    },
    {
        name: 'reviewName2',
        location: 'reviewLocation2',
        rating: 4,
        comment: 'reviewComment2',
    },
    {
        name: 'reviewName3',
        location: 'reviewLocation3',
        rating: 5,
        comment: 'reviewComment3',
    },
    
];

const Review = () => {
    const [itemsToShow, setItemsToShow] = useState(3);
    const [currentIndex, setCurrentIndex] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({
            duration: 1000,
            offset: 100,
        });

        // Update itemsToShow based on screen size
        const updateItemsToShow = () => {
            if (window.innerWidth >= 1024) {
                setItemsToShow(3); // Large screens
            } else if (window.innerWidth >= 768) {
                setItemsToShow(2); // Medium screens
            } else {
                setItemsToShow(1); // Small screens
            }
        };

        window.addEventListener('resize', updateItemsToShow);
        updateItemsToShow(); // Call initially

        return () => {
            window.removeEventListener('resize', updateItemsToShow);
        };
    }, []);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex + itemsToShow < reviews.length ? prevIndex + itemsToShow : 0
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex - itemsToShow >= 0 ? prevIndex - itemsToShow : reviews.length - itemsToShow
        );
    };

    const handleWriteReview = () => {
        window.location.href = 'mailto:info@helvetialimousine.ch?subject=Write a Review';
    };

    return (
        <div className='w-full  pb-[100px] pt-[100px]  bg-[#242424] flex flex-col justify-center items-center'>
            <span className='text-[45px] tracking-[3px] font-[600] text-white p-4' data-aos="fade-up" dangerouslySetInnerHTML={{ __html: t("what_our_customers_say") }}/>
            <span className='text-[16px] tracking-widest text-[#ffffff99] w-[95vw] xl:w-[70vw] 3xl:w-[50vw] m-auto font-[300] text-center mb-[100px] p-8 xl:p-0 mt-8' data-aos="fade-up" dangerouslySetInnerHTML={{__html: t("company_description")}} />

            {/* Slider */}
            <div className='w-full max-w-[1200px] overflow-hidden p-2' data-aos="fade-up">
                {/* Prev & Next Button */}
                <div className='flex justify-end gap-3 items-center mt-4 text-[600]'>
                    <button
                        disabled={currentIndex === 0}
                        onClick={prevSlide}
                        className='hover:bg-[#da291c] p-2 border-2 border-[#da291c]  transition disabled:hover:bg-transparent disabled:border-gray-400'>
                        <IoIosArrowBack className='text-white' />
                    </button>
                    <button
                        disabled={currentIndex + itemsToShow >= reviews.length}
                        onClick={nextSlide}
                        className='hover:bg-[#da291c] p-2 border-2 border-[#da291c] transition disabled:hover:bg-transparent disabled:border-gray-400'>
                        <IoIosArrowForward className='text-white' />
                    </button>
                </div>


                {/* Review Card */}
                <div className='flex transition-transform duration-500 ease-out gap-0 lg:gap-6'
                    style={{ transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)` }}
                >
                    {reviews.map((review, index) => (
                        <SingleReview key={index} review={review} />
                    ))}
                </div>
            </div>
            <button
                onClick={handleWriteReview}
                className='group mt-8 w-[200px] bg-[#ffffff] text-black py-2 px-4 rounded-full hover:bg-[#da291c] hover:text-white transition flex items-center justify-center gap-2'
            >
                {t('write_a_review')}
                <BiPencil className="text-black group-hover:text-white transition" /> {/* Pencil icon */}
            </button>

        </div>
    );
};

export default Review;
