import React from 'react';
import Banner from '../components/Banner';
import BlogBanner from '../assets/images/banner/blog.jpg'
import ContactInformation from '../components/common-layout/contactInformation';
import { useNavigate, useParams } from 'react-router-dom';
import { blogs } from '../utils/helper';
import { GoDotFill } from "react-icons/go";
import SEO from '../components/SEO';
import { useTranslation } from 'react-i18next';

const StarsList = ({ stars,t }) => {
  return (
    <ul className="space-y-2 mt-4">
      {stars.map((star, index) => (
        <li key={index} className="flex items-start ml-5">
          <GoDotFill className="text-[#da291c] mt-1 mr-2" />
          <span className="text-gray-700">{t(`${star}`)}</span>
        </li>
      ))}
    </ul>
  );
};

const SingleBlog = () => {
  const { t } = useTranslation();
  const { uniqueKey } = useParams();
  const blog = blogs.find(blog => blog.uniqueKey === uniqueKey);

  // Function to parse the date string
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.replace('.', '').split(' ');
    const monthMap = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    };
    return new Date(`${year}-${monthMap[month]}-${day}`);
  };

  // Filter and sort blogs to get the 5 latest, excluding the current blog
  const latestBlogs = blogs
    .filter((b) => b.uniqueKey !== uniqueKey) // Exclude current blog
    .sort((a, b) => parseDate(b.date) - parseDate(a.date)) // Sort by date, latest first
    .slice(0, 6); // Get the latest 5 blogs

  const navigate = useNavigate();
  const handleRedirect = (uniqueKey) => {
    navigate(`/blog/${uniqueKey}`);
  };


  return (
    <>
      <SEO title={`${t("blog")} | Helvetia Limousine`}>
        <Banner title={t("blog")} imageURL={BlogBanner} />
        <div className="bg-white min-h-screen md:bg-custom-gradient flex justify-center pt-[100px] pb-[100px] md:pb-[200px]">
          <div className="flex flex-col md:flex-row justify-between w-[90vw] md:w-[80vw] lg:w-[80vw] xl:w-[70vw] 3xl:w-[50vw] items-start gap-8 ">
            {/* First Div: 65% Width */}
            <div className="w-[85vw] md:w-[60%] lg:w-[65%] xl:w-[70%] pr-0 lg:pr-6 pl-0 space-y-12 ">
              <h1 className='font-bold text-xl md:text-4xl '>
                {t(`${blog.title}`)}
              </h1>
              <img src={blog.image} alt="" className='w-[85vw] md:w-full' />
              {blog.contents.map((content, index) => (
                <div key={index} className="space-y-2">
                  <p className="" dangerouslySetInnerHTML={{ __html: t(`${content.title}`)}} />
                </div>
              ))}
            </div>

            {/* Second Div: 25% Width */}
            <div className="w-[95%] md:w-[35%] xl:w-[25%] bg-white shadow-md">
              <div className="bg-gray-900 p-3">
                <h3 className="text-[#da291c] font-bold text-md">{t("lastest_blogs")}</h3>
              </div>
              <ul className="space-y-4 px-5 py-3">
                {latestBlogs.map((latestBlog, index) => (
                  <li key={index} onClick={() => handleRedirect(latestBlog.uniqueKey)}>
                    <p className="text-sm text-gray-500">{latestBlog.date}</p>
                    <h4 className="text-[15px] font-medium text-gray-800 hover:text-[#da291c] cursor-pointer">
                      {t(`${latestBlog.title}`)}
                    </h4>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </SEO>
    </>
  );
};

export default SingleBlog;
