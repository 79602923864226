import React from 'react';
import Banner from '../components/Banner';
import BlogBanner from '../assets/images/banner/blog.jpg'
import { blogs } from '../utils/helper';
import { useNavigate } from 'react-router-dom';
import SEO from '../components/SEO';
import { useTranslation } from 'react-i18next';



const Blog = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleRedirect = (uniqueKey) => {
    navigate(`/blog/${uniqueKey}`);
  };

  return (
    <>
      <SEO title={`${t("blog")} | Helvetia Limousine`} >
        <Banner title={"Blog"} imageURL={BlogBanner} />
        <div className="bg-white md:bg-custom-gradient flex justify-center pt-[100px] pb-[100px] md:pb-[200px]">
          <div className="max-w-7xl px-4">
            {/* Grid layout for the blogs */}
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
              {blogs.map((blog, index) => (
                <div key={index} className=" group rounded-lg flex flex-col bg-white shadow-lg  overflow-hidden max-h-[400px] cursor-pointer " onClick={() => handleRedirect(blog.uniqueKey)}>

                  {/* Blog image */}
                  <img src={blog.image} alt={blog.title} className="h-48 object-cover" />
                  
                  {/* Blog content */}
                  <div className="p-6 flex-grow">
                    <p className="text-sm text-gray-500 mb-5">{blog.date}</p>
                    <h3 className="text-xl font-semibold mb-3 border-l-4 border-[#da291c] pl-4 hover:text-[#da291c]">{t(`${blog.title}`)}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </SEO>
    </>
  );
};

export default Blog;
