import React from 'react';
import Banner from '../components/Banner';
import FAQBanner from '../assets/images/banner/faq.jpg';
import Faq from '../components/common-layout/Faq';
import SEO from '../components/SEO';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title={`${t("faq")} | Helvetia Limousine`}>
        <Banner title={t("faq")} imageURL={FAQBanner} />
        <Faq from={"FAQ"} />
      </SEO>
    </>
  );
};

export default FAQ;
