import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';

const FinishPayment = ({ bookingData, chosenCar, passengerDetails, paymentSystem, totalPrice, appDuration }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language; // Get the current language ('en' or 'de')
    const formatToUTC = (dateTime) => {
        return dateTime ? new Date(dateTime).toISOString() : '';
      };
    // Function to format the table content
    const formatTableContent = () => {
        return `
            <table border="1" cellpadding="5" cellspacing="0" style="border-collapse:collapse; width: 100%;">
                ${(bookingData.category === 'perKm' && bookingData.pickupLocation) ? `
                <tr>
                    <td><strong>${t("pickup_location")}</strong></td>
                    <td>${bookingData.pickupLocation.formatted_address}</td>
                </tr>
                <tr>
                    <td><strong>${t("start_date")}</strong></td>
                    <td>${formatToUTC(bookingData.startDateTime)}</td>
                </tr>` : `
                <tr>
                    <td><strong>${t("start_location")}</strong></td>
                    <td>${bookingData.startLocation.formatted_address}</td>
                </tr>
                <tr>
                    <td><strong>${t("date_and_time")}</strong></td>
                    <td>${formatToUTC(bookingData.startDateTime)}</td>
                </tr>`}
                
                ${(bookingData.category === 'perKm') ? `
                <tr>
                    <td><strong>${t("transfer_type")}</strong></td>
                    <td>${bookingData.tripType}</td>
                </tr>` : ''}
                
                ${(bookingData.category === 'perKm' && bookingData.tripType === 'roundTrip') ? `
                <tr>
                    <td><strong>${t("return_date")}</strong></td>
                    <td>${formatToUTC(bookingData.returnDateTime)}</td>
                </tr>` : ''}
                
                ${(bookingData.category === 'perKm' && bookingData.dropoffLocation) ? `
                <tr>
                    <td><strong>${t("destination")}</strong></td>
                    <td>${bookingData.dropoffLocation.formatted_address}</td>
                </tr>` : ''}

                ${(bookingData.category === 'perHour') ? `
                <tr>
                    <td><strong>${t("trip_duration")}</strong></td>
                    <td>${bookingData.tripHour}</td>
                </tr>` : `
                <tr>
                    <td><strong>${t("duration")}</strong></td>
                    <td>${appDuration}</td>
                </tr>`}
                
                <tr>
                    <td><strong>${t("total_price")}</strong></td>
                    <td>CHF ${totalPrice} (${paymentSystem == 'cash' ? 'Paying with cash' : 'Credit card in the vehicle'})</td>
                </tr>
                
                <tr>
                    <td><strong>${t("booking_vehicles")}</strong></td>
                    <td>${t(`${chosenCar.category}`)}</td>
                </tr>
                <tr>
                    <td><strong>${t("booking_vehicle_model")}</strong></td>
                    <td>${t(`${chosenCar.model}`)}</td>
                </tr>
                
                <tr>
                    <td><strong>${t("booking_passengers")}</strong></td>
                    <td>${passengerDetails.passengers}</td>
                </tr>
                
                <tr>
                    <td><strong>${t("Baggage")}</strong></td>
                    <td>${passengerDetails.baggage}</td>
                </tr>
                
                ${(passengerDetails.childSeatValues.length > 0) ? `
                <tr>
                    <td><strong>${t("booking_child_seat")}</strong></td>
                    <td>${passengerDetails.childSeatValues.map((seat, index) => `#${index + 1} ${t("booking_child_seat")} ${seat}`).join('<br>')}</td>
                </tr>` : ''}
                
                ${(passengerDetails.flightNumber) ? `
                <tr>
                    <td><strong>${t("booking_flight_number")}</strong></td>
                    <td>${passengerDetails.flightNumber}</td>
                </tr>` : ''}
                
                ${(passengerDetails.nameBoard) ? `
                <tr>
                    <td><strong>${t("booking_name_board")}</strong></td>
                    <td>${passengerDetails.nameBoard}</td>
                </tr>` : ''}
                
                ${(passengerDetails.notes) ? `
                <tr>
                    <td><strong>${t("booking_driver_note")}</strong></td>
                    <td>${passengerDetails.notes}</td>
                </tr>` : ''}
                
                <tr>
                    <td><strong>${t("name_and_surname")}</strong></td>
                    <td>${passengerDetails.name} ${passengerDetails.lastName}</td>
                </tr>
                
                ${(passengerDetails.companyName) ? `
                <tr>
                    <td><strong>${t("company_name")}</strong></td>
                    <td>${passengerDetails.companyName}</td>
                </tr>` : ''}
                
                <tr>
                    <td><strong>E-mail</strong></td>
                    <td>${passengerDetails.email}</td>
                </tr>
                
                <tr>
                    <td><strong>${t("telephone")}</strong></td>
                    <td>${passengerDetails.phoneNo}</td>
                </tr>
            </table>
        `;
    };

    const formatAutoReplyGerman = () => {
        return `
            <div>
                <p>Liebe/r ${passengerDetails.name},</p>
                <p>
                Vielen Dank, dass Sie sich für Helvetia Limousine entschieden haben. Ihre Fahrt von
                 ${(bookingData.category === 'perKm' && bookingData.pickupLocation) ?
                `${bookingData.pickupLocation.formatted_address} nach ${bookingData.dropoffLocation.formatted_address}` :
                `${bookingData.startLocation.formatted_address}`} 
                   wurde erfolgreich gebucht.</p>

                <p>Wir freuen uns, Ihnen einen luxuriösen und komfortablen Service anbieten zu dürfen.</p>
                
                
                <p>Hier sind die Details Ihrer Buchung:</p>
                <ul>
                    ${(bookingData.category === 'perKm' && bookingData.pickupLocation) ? `
                    <li><strong>${t("pickup_location")}:</strong> ${bookingData.pickupLocation.formatted_address}</li>
                    <li><strong>${t("start_date")}:</strong> ${formatToUTC(bookingData.startDateTime)}</li>` : `
                    <li><strong>${t("start_location")}:</strong> ${bookingData.startLocation.formatted_address}</li>
                    <li><strong>${t("date_and_time")}:</strong> ${formatToUTC(bookingData.startDateTime)}</li>`}
    
                    ${(bookingData.category === 'perKm') ? `
                    <li><strong>${t("transfer_type")}:</strong> ${bookingData.tripType}</li>` : ''}
                    
                    ${(bookingData.category === 'perKm' && bookingData.tripType === 'roundTrip') ? `
                    <li><strong>${t("return_date")}:</strong> ${formatToUTC(bookingData.returnDateTime)}</li>` : ''}
                    
                    ${(bookingData.category === 'perKm' && bookingData.dropoffLocation) ? `
                    <li><strong>${t("destination")}:</strong> ${bookingData.dropoffLocation.formatted_address}</li>` : ''}
    
                    ${(bookingData.category === 'perHour') ? `
                    <li><strong>${t("trip_duration")}:</strong> ${bookingData.tripHour}</li>` : `
                    <li><strong>${t("duration")}:</strong> ${appDuration}</li>`}
    
                    <li><strong>${t("total_price")}:</strong> CHF ${totalPrice} (${paymentSystem === 'cash' ? 'Paying with cash' : 'Credit card in the vehicle'})</li>
                    <li><strong>${t("booking_vehicles")}:</strong> ${t(`${chosenCar.category}`)}</li>
                    <li><strong>${t("booking_vehicle_model")}:</strong> ${t(`${chosenCar.model}`)}</li>
    
                    <li><strong>${t("booking_passengers")}:</strong> ${passengerDetails.passengers}</li>
                    <li><strong>${t("Baggage")}:</strong> ${passengerDetails.baggage}</li>
    
                    ${(passengerDetails.childSeatValues.length > 0) ? `
                    <li><strong>${t("booking_child_seat")}:</strong> ${passengerDetails.childSeatValues.map((seat, index) => `#${index + 1} ${t("booking_child_seat")} ${seat}`).join('<br>')}</li>` : ''}
    
                    ${(passengerDetails.flightNumber) ? `
                    <li><strong>${t("booking_flight_number")}:</strong> ${passengerDetails.flightNumber}</li>` : ''}
    
                    ${(passengerDetails.nameBoard) ? `
                    <li><strong>${t("booking_name_board")}:</strong> ${passengerDetails.nameBoard}</li>` : ''}
    
                    ${(passengerDetails.notes) ? `
                    <li><strong>${t("booking_driver_note")}:</strong> ${passengerDetails.notes}</li>` : ''}
    
                    <li><strong>${t("name_and_surname")}:</strong> ${passengerDetails.name} ${passengerDetails.lastName}</li>
    
                    ${(passengerDetails.companyName) ? `
                    <li><strong>${t("company_name")}:</strong> ${passengerDetails.companyName}</li>` : ''}
    
                    <li><strong>E-mail:</strong> ${passengerDetails.email}</li>
                    <li><strong>${t("telephone")}:</strong> ${passengerDetails.phoneNo}</li>
                </ul>
                
                <p><strong>Stornierungsbedingungen:</strong></p>
                <p>Bitte beachten Sie, dass im Falle einer Stornierung folgende Bedingungen gelten:</p>
                <ul>
                    <li>Bis 24 Stunden vor Reisebeginn: 20 % des Gesamtpreises werden in Rechnung gestellt.</li>
                    <li>Weniger als 24 Stunden vor Reisebeginn: 50 % des Gesamtpreises werden berechnet.</li>
                    <li>Weniger als 4 Stunden vor Reisebeginn: 100 % des Gesamtpreises werden berechnet.</li>
                    <li>Sollte der Kunde zum vereinbarten Termin nicht erscheinen, wird der volle Betrag der Buchung in Rechnung gestellt.</li>
                </ul>
                <p>Herzliche Grüsse,<br/>Ihr Helvetia Limousine Team</p>
            </div>
        `;
    };


    const formatAutoReplyEnglish = () => {
        return `
            <div>
                <p>Dear ${passengerDetails.name},</p>
                <p>
                Thank you for choosing Helvetia Limousine. Your trip from
                 ${(bookingData.category === 'perKm' && bookingData.pickupLocation) ?
                `${bookingData.pickupLocation.formatted_address} to ${bookingData.dropoffLocation.formatted_address}` :
                `${bookingData.startLocation.formatted_address}`} 
                    has been successfully booked.</p>
                <p>We are pleased to offer you a luxurious and comfortable service.</p>
                
                <p>Here are the details of your booking:</p>
                <ul>
                    ${(bookingData.category === 'perKm' && bookingData.pickupLocation) ? `
                    <li><strong>${t("pickup_location")}:</strong> ${bookingData.pickupLocation.formatted_address}</li>
                    <li><strong>${t("start_date")}:</strong> ${formatToUTC(bookingData.startDateTime)}</li>` : `
                    <li><strong>${t("start_location")}:</strong> ${bookingData.startLocation.formatted_address}</li>
                    <li><strong>${t("date_and_time")}:</strong> ${formatToUTC(bookingData.startDateTime)}</li>`}
    
                    ${(bookingData.category === 'perKm') ? `
                    <li><strong>${t("transfer_type")}:</strong> ${bookingData.tripType}</li>` : ''}
                    
                    ${(bookingData.category === 'perKm' && bookingData.tripType === 'roundTrip') ? `
                    <li><strong>${t("return_date")}:</strong> ${formatToUTC(bookingData.returnDateTime)}</li>` : ''}
                    
                    ${(bookingData.category === 'perKm' && bookingData.dropoffLocation) ? `
                    <li><strong>${t("destination")}:</strong> ${bookingData.dropoffLocation.formatted_address}</li>` : ''}
    
                    ${(bookingData.category === 'perHour') ? `
                    <li><strong>${t("trip_duration")}:</strong> ${bookingData.tripHour}</li>` : `
                    <li><strong>${t("duration")}:</strong> ${appDuration}</li>`}
    
                    <li><strong>${t("total_price")}:</strong> CHF ${totalPrice} (${paymentSystem === 'cash' ? 'Paying with cash' : 'Credit card in the vehicle'})</li>
                    <li><strong>${t("booking_vehicles")}:</strong> ${t(`${chosenCar.category}`)}</li>
                    <li><strong>${t("booking_vehicle_model")}:</strong> ${t(`${chosenCar.model}`)}</li>
    
                    <li><strong>${t("booking_passengers")}:</strong> ${passengerDetails.passengers}</li>
                    <li><strong>${t("Baggage")}:</strong> ${passengerDetails.baggage}</li>
    
                    ${(passengerDetails.childSeatValues.length > 0) ? `
                    <li><strong>${t("booking_child_seat")}:</strong> ${passengerDetails.childSeatValues.map((seat, index) => `#${index + 1} ${t("booking_child_seat")} ${seat}`).join('<br>')}</li>` : ''}
    
                    ${(passengerDetails.flightNumber) ? `
                    <li><strong>${t("booking_flight_number")}:</strong> ${passengerDetails.flightNumber}</li>` : ''}
    
                    ${(passengerDetails.nameBoard) ? `
                    <li><strong>${t("booking_name_board")}:</strong> ${passengerDetails.nameBoard}</li>` : ''}
    
                    ${(passengerDetails.notes) ? `
                    <li><strong>${t("booking_driver_note")}:</strong> ${passengerDetails.notes}</li>` : ''}
    
                    <li><strong>${t("name_and_surname")}:</strong> ${passengerDetails.name} ${passengerDetails.lastName}</li>
    
                    ${(passengerDetails.companyName) ? `
                    <li><strong>${t("company_name")}:</strong> ${passengerDetails.companyName}</li>` : ''}
    
                    <li><strong>E-mail:</strong> ${passengerDetails.email}</li>
                    <li><strong>${t("telephone")}:</strong> ${passengerDetails.phoneNo}</li>
                </ul>
                
                <p><strong>Cancellation Policy:</strong></p>
                <p>Please note the following cancellation conditions:</p>
                <ul>
                    <li>Up to 24 hours before the trip: 20% of the total price will be charged.</li>
                    <li>Less than 24 hours before the trip: 50% of the total price will be charged.</li>
                    <li>Less than 4 hours before the trip: 100% of the total price will be charged.</li>
                    <li>If the customer does not appear at the agreed time, the full booking amount will be charged.</li>
                </ul>
                <p>Best regards,<br/>Your Helvetia Limousine Team</p>
            </div>
        `;
    };




    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            if (bookingData && chosenCar && passengerDetails && totalPrice && paymentSystem && appDuration) {
                handleSubmit(); // This will only log once
                isFirstRender.current = false; // Mark as not the first render anymore
            }
        }
    }, [bookingData, chosenCar, passengerDetails, totalPrice, paymentSystem, appDuration]);

    const autoReply = currentLanguage === 'en' ? formatAutoReplyEnglish() : formatAutoReplyGerman();

    const handleSubmit = () => {
        const templateParams = {
            first_name: passengerDetails.name,
            last_name: passengerDetails.lastName,
            message_html: formatTableContent(), // Send the formatted table content as the message
            subject: `${t("email_subject")}`,
            body: `${t("email_body")}`,
            auto_reply_html: autoReply,
            reply_to: passengerDetails.email,
            auto_reply_subject: `${t('auto_reply_subject')}`,
        };

        emailjs.send(
            'service_6r943dn', // Replace with your EmailJS service ID
            'template_oglalv3', // Replace with your EmailJS template ID
            templateParams,
            'HwrvQN_G0q1gDu8bf' // Replace with your EmailJS user ID
        )
            .then((result) => {
                alert('Message sent successfully!');
            }, (error) => {
                console.error(error.text);
                alert('Failed to send message. Please try again.');
            });
    };

    return (
        <div className='flex flex-col items-center'>
            <div className='p-8 px-16 bg-green-200 border-[1px] border-green-500'>
                <p className='text-center w-full font-[280] text-[14px]'>
                    {t('booked_successfull_mssg')}
                </p>
            </div>

            <div className='mt-6 w-[100%]'>
                <h2 className='font-bold text-[24px] text-center mb-4'>{t('transfer_data_heading')}</h2>
                <div className='w-full flex justify-center'>
                    <table className='table-auto border-collapse border border-gray-300 w-[90%]'>
                        <tbody>
                            {bookingData.category === 'perKm' &&
                                <tr>
                                    <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("pickup_location")}</td>
                                    <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{bookingData.pickupLocation.formatted_address}</td>
                                </tr>

                            }
                            {bookingData.category === 'perHour' &&
                                <tr>
                                    <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("start_location")}</td>
                                    <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{bookingData.startLocation.formatted_address}</td>
                                </tr>

                            }
                            {bookingData.category === 'perHour' &&
                                <tr>
                                    <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("date_and_time")}</td>
                                    <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{bookingData.startDateTime}</td>
                                </tr>

                            }
                            {bookingData.category === 'perKm' &&
                                <tr>
                                    <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("start_date")}</td>
                                    <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{bookingData.startDateTime}</td>
                                </tr>
                            }




                            {bookingData.category === 'perKm' &&
                                <tr>
                                    <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("transfer_type")}</td>
                                    <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{bookingData.tripType}</td>
                                </tr>
                            }


                            {(bookingData.category === 'perKm' && bookingData.tripType === 'roundTrip') &&
                                <tr>
                                    <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("return_date")}</td>
                                    <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{bookingData.returnDateTime}</td>
                                </tr>
                            }

                            {bookingData.category === 'perKm' &&
                                <tr>
                                    <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("destination")}</td>
                                    <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{bookingData.dropoffLocation.formatted_address}</td>
                                </tr>

                            }



                            {bookingData.category === 'perHour' ?
                                <tr>
                                    <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("trip_duration")}</td>
                                    <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{bookingData.tripHour}</td>
                                </tr>
                                : <tr>
                                    {/* calculation */}
                                    <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("duration")}</td>
                                    <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{appDuration}</td>
                                </tr>
                            }
                            <tr>
                                {/* total price + payment System */}
                                <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("total_price")}</td>
                                <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>CHF {totalPrice} ({paymentSystem == 'cash' ? 'Paying with cash' : 'Credit card in the vehicle'})</td>
                            </tr>
                            <tr>
                                <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("booking_vehicles")}</td>
                                <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{t(`${chosenCar.category}`)}</td>
                            </tr>
                            <tr>
                                <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("booking_vehicle_model")}</td>
                                <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{t(`${chosenCar.model}`)}</td>
                            </tr>
                            <tr>
                                <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("booking_passengers")}</td>
                                <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{passengerDetails.passengers}</td>
                            </tr>
                            <tr>
                                <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("Baggage")}</td>
                                <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{passengerDetails.baggage}</td>
                            </tr>
                            <tr>
                                {/* Conditional */}
                                <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("booking_child_seat")}</td>
                                <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>
                                    {passengerDetails.childSeatValues.length && passengerDetails.childSeatValues.map((seat, index) => (
                                        <>
                                            <span key={index}>#{index + 1} {t("booking_child_seat")} {seat}</span><br />
                                        </>
                                    ))}
                                </td>
                            </tr>

                            {passengerDetails.flightNumber &&
                                <tr>
                                    {/* conditional */}
                                    <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("booking_flight_number")}</td>
                                    <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{passengerDetails.flightNumber}</td>
                                </tr>

                            }
                            {passengerDetails.nameBoard &&
                                <tr >
                                    {/* conditional */}
                                    <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("booking_name_board")}</td>
                                    <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{passengerDetails.nameBoard}</td>
                                </tr>
                            }
                            {passengerDetails.notes &&
                                <tr >
                                    {/* conditional */}
                                    <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("booking_driver_note")}</td>
                                    <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{passengerDetails.notes}</td>
                                </tr>
                            }
                            <tr>
                                {/* name + lastname */}
                                <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("name_and_surname")}</td>
                                <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{passengerDetails.name} {passengerDetails.lastName}</td>
                            </tr>
                            {passengerDetails.companyName &&
                                <tr >
                                    {/* conditional */}
                                    <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("company_name")}</td>
                                    <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{passengerDetails.companyName}</td>
                                </tr>
                            }
                            <tr>
                                <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>E-mail</td>
                                <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{passengerDetails.email}</td>
                            </tr>
                            <tr>
                                <td className='text-[14px] bg-[#da291c] text-white border border-gray-300 px-4 py-2 font-semibold'>{t("telephone")}</td>
                                <td className='border w-[70%] text-[12px] border-gray-300 px-4 py-2'>{passengerDetails.phoneNo}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <div> */}
            <Link to='/' className="mt-12 bg-white border-[1px] border-gray-300 text-black uppercase hover:bg-[#da291c] hover:text-white hover:border-[#da291c]  tracking-[2px] py-2 px-10">
                {t('back_to_home_btn')}
            </Link>
            {/* </div> */}
        </div>
    )
}

export default FinishPayment;
