import React from 'react';
import Banner from '../components/Banner';
import TermBanner from '../assets/images/banner/term.jpg';
import ContactInformation from '../components/common-layout/contactInformation';
import SEO from '../components/SEO';
import { useTranslation } from 'react-i18next';

const Term = () => {
  const { t } = useTranslation();
  
  // Helper function to inject raw HTML safely
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  // All terms including sub-terms can be placed in an array
  const terms = [
    { content: t('term_1') },
    { content: t('term_2') },
    { content: t('term_3') },
    { content: t('term_4') },
    { 
      content: t('term_5'), 
      subterms: [
        t('term_5_1'),
        t('term_5_2'),
        t('term_5_3')
      ]
    },
    { content: t('term_6') },
    { content: t('term_7') },
    { content: t('term_8') },
    { content: t('term_9') },
    { content: t('term_10') },
    { content: t('term_11') },
    { content: t('term_12') },
    { content: t('term_13') },
    { content: t('term_14') },
    { content: t('term_15') },
    { content: t('term_16') },
    { content: t('term_17') },
    { content: t('term_18') },
    { content: t('term_19') },
    { content: t('term_20') },
    // You can add more terms as necessary
  ];

  return (
    <>
      <SEO title={`${t("term")} | Helvetia Limousine`} />
      <Banner title={t("term")} imageURL={TermBanner} />
      <div className="bg-white md:bg-custom-gradient flex justify-center pt-[100px] pb-[100px] md:pb-[200px]">
        <div className="flex flex-col md:flex-row justify-between w-[90vw] xl:w-[70vw] 3xl:w-[55vw] items-start gap-8">
          
          {/* First Div: 65% Width */}
          <div className="w-full md:w-[60%] lg:w-[65%] pr-0 lg:pr-6 pl-0 lg:pl-6">
            <h1 className=" text-[25px] md:text-[35px] font-semibold mb-4">{t("terma_and_conditions")}</h1>
            
            {/* Ordered list for terms */}
            <ol className="space-y-6 list-decimal ml-4 mt-16">
              {terms.map((term, index) => (
                <li key={index} className="mb-4">
                  {/* Render the main term */}
                  <p dangerouslySetInnerHTML={createMarkup(term.content)}></p>
                  
                  {/* If subterms exist, render them as nested list */}
                  {term.subterms && (
                    <ul className="ml-6 list-disc">
                      {term.subterms.map((subterm, subIndex) => (
                        <li key={subIndex} dangerouslySetInnerHTML={createMarkup(subterm)}></li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ol>
          </div>
          
          {/* Second Div: 25% Width */}
          <ContactInformation />
        </div>
      </div>
    </>
  );
};

export default Term;
