import React, { useState, useEffect } from 'react';
import Banner from '../components/Banner';
import ContactBanner from '../assets/images/banner/contact.jpg';
import ContactInformation from '../components/common-layout/contactInformation';
import SEO from '../components/SEO';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import GoogleMap from '../components/GoogleMap';

const Contact = () => {
  const { t } = useTranslation();

  // State for dynamic sum validation
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [userSum, setUserSum] = useState('');
  const [isSumValid, setIsSumValid] = useState(false);

  useEffect(() => {
    generateRandomNumbers(); // Generate random numbers on component mount
  }, []);

  const generateRandomNumbers = () => {
    const newNum1 = Math.floor(Math.random() * 10) + 1; // Random number between 1 and 10
    const newNum2 = Math.floor(Math.random() * 10) + 1; // Random number between 1 and 10
    setNum1(newNum1);
    setNum2(newNum2);
  };

  const handleSumChange = (e) => {
    const value = e.target.value;
    setUserSum(value);
    // Validate sum input
    setIsSumValid(parseInt(value) === num1 + num2);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isSumValid) return; // Prevent form submission if sum is incorrect

    emailjs.sendForm(
      'service_fuljmpl', // Replace with your EmailJS service ID
      'template_lo2qluc', // Replace with your EmailJS template ID
      e.target,
      'Jpsao3P8jReXUDdst' // Replace with your EmailJS user ID
    )
    .then((result) => {
      alert('Message sent successfully!');
      e.target.reset(); // Reset the form fields
      setUserSum(''); // Reset userSum state
      generateRandomNumbers(); 
    }, (error) => {
      console.error(error.text);
      alert('Failed to send message. Please try again.');
    });
  };

  return (
    <>
      <SEO title={`${t("contact")} | Helvetia Limousine`}>
        <Banner title={t("contact")} imageURL={ContactBanner} />
        <div className="bg-white md:bg-custom-gradient flex justify-center pt-[100px] pb-[100px] md:pb-[200px]">
          <div className="flex flex-col md:flex-row justify-between w-[90vw] xl:w-[70vw] 3xl:w-[60vw] items-start gap-8 ">
            {/* First Div: 65% Width */}
            <div className="w-full md:w-[60%] lg:w-[65%] xl:w-[55%] pr-0 lg:pr-6 pl-0  ">
              {/* Content for the first div */}
              <h1 className="text-[35px] font-bold mb-4">{t("contact_form")}</h1>
              <form className="flex flex-col gap-6 mt-[50px] mb-[50px]" onSubmit={handleSubmit}>
                {/* First Name and Last Name */}
                <div className="flex gap-4">
                  <input
                    type="text"
                    name="first_name"
                    placeholder={t("first_name")}
                    className="w-full p-2 border-2 border-gray-900"
                    required
                  />
                  <input
                    type="text"
                    name="last_name"
                    placeholder={t("last_name")}
                    className="w-full p-2 border-2 border-gray-900"
                    required
                  />
                </div>
                {/* Email */}
                <input
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  className="w-full p-2 border-2 border-gray-900"
                  required
                />
                {/* Message */}
                <textarea
                  name="message"
                  placeholder={t("message_question")}
                  className="w-full p-2 border-2 border-gray-900"
                  rows="4"
                  required
                ></textarea>
                {/* Sum and Confirmation */}
                <div className="flex items-center gap-2">
                  <label>{`Summe ${num1} + ${num2} =`}</label>
                  <input
                    type="text"
                    value={userSum}
                    onChange={handleSumChange}
                    className="w-16 p-2 border-2 border-gray-900"
                    required
                  />
                </div>
                {/* Submit Button */}
                <button
                  type="submit"
                  className={`w-full bg-[#da291c] text-white py-2 font-bold  ${isSumValid ? 'hover:bg-[#b72318]' : 'opacity-50 cursor-not-allowed'}`}
                  disabled={!isSumValid}
                >
                  {t("send")}
                </button>
              </form>
              <GoogleMap classNames='w-[100%] md:w-[420px] lg:w-[600px] xl:w-[800px] 3xl:w-[850px] h-[500px]' />
            </div>

            {/* Second Div: 25% Width */}
            <ContactInformation />
          </div>
        </div>
      </SEO>
    </>
  );
};

export default Contact;
