import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Banner from '../components/Banner';
import SEO from '../components/SEO';
import { useTranslation } from 'react-i18next';
import { services } from '../utils/helper';

const SliderDetails = () => {
    const { t } = useTranslation();
    const { uniqueKey } = useParams();
    const service = services.find((service) => service.uniqueKey === uniqueKey);
    return (
        <>
            <SEO title={`${t("service")} | Helvetia Limousine`} >
                <Banner title={""} imageURL={service.image} />
                <div className="bg-white min-h-screen md:bg-custom-gradient flex justify-center pt-[100px] pb-[100px] md:pb-[200px]">
                    <div className='w-[90vw] md:w-[40vw] mr-0 md:mr-20' >
                        <h1 className='text-4xl font-bold'>{t(`${service.title}`)}</h1>
                        <p className=' mt-10' dangerouslySetInnerHTML={{ __html: t(`${service.introduction}`) }} />
                        <img className='mt-10' src={service.image} alt="" />
                        {
                            service.description.map((description, index) => (
                                <p key={index} className=' mt-10' dangerouslySetInnerHTML={{ __html: t(`${description}`) }} />
                            ))
                        }

                    </div>

                </div>
            </SEO>
        </>
    );
};

export default SliderDetails;
