import React from 'react';
import Banner from '../components/Banner';
import LegalBanner from '../assets/images/banner/legal.jpg'
import ContactInformation from '../components/common-layout/contactInformation';
import AboutImage1 from '../assets/images/about/about1.jpg'
import AboutImage2 from '../assets/images/about/about2.jpg';
// import GermanyFlag from "../assets/images/about/flag-germany.png"
// import EnglandFlag from "../assets/images/about/flag-england.png"
// import TurkishFlag from "../assets/images/about/flag-turkish.png"
import { GoDotFill } from "react-icons/go";
import SEO from '../components/SEO';
import { useTranslation } from 'react-i18next';


const Legal = () => {
    const { t } = useTranslation();
    return (
        <>
            <SEO title={`${t("legal_notice")} | Helvetia Limousine`} >
                <Banner title={t("legal_notice")} imageURL={LegalBanner} />
                <div className="bg-white md:bg-custom-gradient flex justify-center pt-[100px] pb-[100px] md:pb-[200px]">
                    <div className="flex flex-col md:flex-row justify-between w-[90vw] xl:w-[70vw] 3xl:w-[45vw] items-start gap-8 ">
                        <div className="p-6 max-w-4xl mx-auto bg-white rounded-xl shadow-xl space-y-4 ">
                            <h1 className="text-2xl font-bold text-gray-900">{"Helvetia Limousine"}</h1>
                            <div className="space-y-1">
                                <p className="text-sm text-gray-600">
                                    <strong className='mr-1'>{t('company_info.business')}:</strong> {"Firat Kücükyildiz"}
                                </p>
                                <p className="text-sm text-gray-600">
                                    <strong className='mr-1'>{t('company_info.address')}:</strong> {"Im Feld 24C, 8424 Embrach"}
                                </p>
                                <p className="text-sm text-gray-600">
                                    <strong className='mr-1'>{t('company_info.email')}:</strong>
                                    <a className="text-blue-500 hover:underline" href={`mailto:info@helvetialimousine.ch`}>{"info@helvetialimousine.ch"}</a>
                                </p>
                                <p className="text-sm text-gray-600">
                                    <strong className='mr-1'>{t('company_info.website')}:</strong>
                                    <a className="text-blue-500 hover:underline" href={"https://helvetialimousine.ch"} target="_blank" rel="noopener noreferrer">
                                        {"helvetialimousine.ch"}
                                    </a>
                                </p>
                                <p className="text-sm text-gray-600">
                                    <strong className='mr-1'>{t('company_info.web_development')}:</strong>
                                    <a className="text-blue-500 hover:underline" href={"https://icoding.ch/"} target="_blank" rel="noopener noreferrer">
                                        {"icoding.ch"}
                                    </a>
                                </p>
                            </div>

                            <h2 className="text-xl font-semibold text-black">{t('legal_notices.title')}</h2>
                            <p className="text-sm text-gray-700">{t('legal_notices.description')}</p>

                            <h2 className="text-xl font-semibold text-black">{t('warranty.title')}</h2>
                            <p className="text-sm text-gray-700">{t('warranty.description')}</p>

                            <h2 className="text-xl font-semibold text-black">{t('liability_disclaimer.title')}</h2>
                            <p className="text-sm text-gray-700">{t('liability_disclaimer.description')}</p>

                            <h2 className="text-xl font-semibold text-black">{t('link_liability.title')}</h2>
                            <p className="text-sm text-gray-700">{t('link_liability.description')}</p>

                            <h2 className="text-xl font-semibold text-black">{t('copyright.title')}</h2>
                            <p className="text-sm text-gray-700">{t('copyright.description')}</p>

                            <h2 className="text-xl font-semibold text-black">{t('data_protection.title')}</h2>
                            <p className="text-sm text-gray-700" dangerouslySetInnerHTML={{ __html: t("data_protection.description") }} />

                            <footer className="mt-4 text-sm text-black font-bold">
                                {t('copyright_notice')}
                            </footer>
                        </div>
                    </div>
                </div>
            </SEO>
        </>
    );
};

export default Legal;
