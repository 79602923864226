import React from 'react'
import Logo from '../assets/images/helvetia.png';
import { Link } from 'react-router-dom';

const BookingNav = () => {
    const handleCopyNumber = () => {
        const phoneNumber = '+41 78 867 90 93';
        navigator.clipboard.writeText(phoneNumber).then(() => {
            alert('Phone number copied to clipboard!');
        });
    };
    return (
        <div className='h-[150px] xs:h-[100px] bg-black flex justify-around items-center text-white flex-wrap p-4 gap-2 xs:gap-2'>
            <Link to='/'>
                <img
                    src={Logo}
                    className="w-[140px] md:w-[150px]"
                    alt="Helvetia Limousine"
                />
            </Link>
            <div>
                <div className='flex flex-col items-center xs:items-end  justify-center'>
                    <p
                        onClick={handleCopyNumber} className='cursor-pointer'><span className='text-[#da291c] font-semibold'>Tel: </span>+41 78 867 90 93</p>
                    <span className='text-[#da291c] font-semibold'>Email: <a href="mailto:info@helvetialimousine.ch" className='text-white font-normal'>
                        info@helvetialimousine.ch
                    </a></span>
                </div>
            </div>
        </div>
    )
}

export default BookingNav