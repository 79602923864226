import React, { useEffect, useState } from 'react';
import { GoogleMap, DirectionsRenderer, useJsApiLoader } from "@react-google-maps/api";
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt, FaExchangeAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { FaArrowRight } from 'react-icons/fa6';
import { AiFillSignature } from "react-icons/ai";
import { IoMdHome } from "react-icons/io";
import { HiOutlineMailOpen } from "react-icons/hi";
import { MdFlight } from "react-icons/md";
import { SiNamesilo } from "react-icons/si";
import { IoPeople } from "react-icons/io5";
import { MdLuggage } from "react-icons/md";
import { MdChildCare } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { BsSliders2Vertical } from "react-icons/bs";
import { GiAlarmClock } from "react-icons/gi";




const center = {
    lat: 51.1657, // Example Latitude for Germany
    lng: 10.4515, // Example Longitude for Germany
};

const GoogleMapDirection = ({ bookingData, setBookingDistance, chosenCar, passengerDetails, setAppDuration }) => {
    const { pickupLocation, dropoffLocation, startLocation, tripType = '', startDateTime, returnDateTime, category, tripHour } = bookingData || {};
    const { t } = useTranslation();
    const [directions, setDirections] = useState(null);
    const [distance, setDistance] = useState(""); // State to store distance
    const [duration, setDuration] = useState(""); // State to store duration



    // Load Google Maps API using useJsApiLoader
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ['places'], // Load the necessary library for Places Autocomplete
    });

    useEffect(() => {
        if (isLoaded && category === 'perKm') {
            // Calculate the route when the API is loaded and the locations are available
            if (pickupLocation && dropoffLocation) {
                calculateRoute(pickupLocation.place_id, dropoffLocation.place_id);
            }
        }
        if (category === 'perHour') {
            const distanceMatch = tripHour.match(/\((?:inkl\.|incl\.)\s*(\d+)km\)/); // Extract the number before 'km'
            const distance = distanceMatch ? distanceMatch[1] : "0"; // Set distance to '0' if no match
            setDistance(distance);
            setBookingDistance(distance);
        }
    }, [isLoaded, pickupLocation, dropoffLocation, bookingData]);

    // Function to calculate the route
    const calculateRoute = (origin, destination) => {
        if (!isLoaded) return;

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
            {
                origin: { placeId: pickupLocation.place_id },
                destination: { placeId: dropoffLocation.place_id },
                travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                    // Extract distance and duration from the result
                    const leg = result.routes[0].legs[0];
                    const distanceInKm = (leg.distance.value / 1000).toFixed(1);
                    setDistance(distanceInKm);
                    setBookingDistance(distanceInKm);
                    setDuration(leg.duration.text);
                    setAppDuration(leg.duration.text);
                } else {
                    console.error("Error fetching directions", result);
                }
            }
        );
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-GB'); // Format date as DD/MM/YYYY
        const formattedTime = date.toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        }); // Format time as HH:mm
        return `${formattedDate}, ${formattedTime}`;
    };

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    if (loadError) {
        return <div>Error loading maps</div>;
    }



    let totalCHF = 0;
    const totalDistance = parseFloat(distance); // Parse the distance string to a number

    // // First 10 km cost minCHF, anything above 10 km is charged at chosenCar.price per km
    if (totalDistance <= 20) {
        totalCHF = chosenCar.minCHF;
        bookingData?.tripType === 'roundTrip' ? totalCHF = totalCHF * 2 : totalCHF = totalCHF;
    } else {
        const extraKm = totalDistance - 20;
        totalCHF = chosenCar.minCHF + (chosenCar.price * extraKm);
        totalCHF = Math.round(totalCHF * 100) / 100;
        bookingData?.tripType === 'roundTrip' ? totalCHF = totalCHF * 2 : totalCHF = totalCHF;

        // totalCHF = Math.round(totalCHF);
    }


    return (
        <div className="w-full h-full">
            {category === 'perKm' &&
                (<>
                    <GoogleMap
                        mapContainerClassName="w-full h-[300px]"
                        center={center}
                        zoom={6}
                    >
                        {directions && <DirectionsRenderer directions={directions} />}
                    </GoogleMap>
                    <div className="flex justify-between bg-black text-white p-4">
                        <div>
                            <span className="font-bold">{t("duration")} : </span>
                            <span>{duration}</span>
                        </div>
                        <div>
                            <span className="font-bold">{t("distance")} : </span>
                            <span>{distance} {"Km"}</span>
                        </div>
                    </div>
                    <div className="mt-4 flex flex-col gap-4  p-4 text-white">
                        <div className="flex items-center gap-2">
                            <FaMapMarkerAlt />
                            <span>{pickupLocation ? pickupLocation?.name : "Origin not specified"}</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <FaMapMarkerAlt />
                            <span>{dropoffLocation ? dropoffLocation?.name : "Destination not specified"}</span>
                        </div>
                        <div className="flex items-center gap-2">
                            {tripType === "oneWay" ? (
                                <FaArrowRight /> // Single arrow icon for one-way
                            ) : (
                                <FaExchangeAlt /> // Single icon with two directions for round-trip
                            )}
                            <span>{t(`${tripType}`)}</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <FaCalendarAlt />
                            <span>{startDateTime ? formatDate(startDateTime) : "Date/Time not specified"}</span>
                        </div>
                        {
                            returnDateTime && (
                                <div className="flex items-center gap-2">
                                    <FaCalendarAlt />
                                    <span>{startDateTime ? formatDate(returnDateTime) : "Date/Time not specified"}</span>
                                </div>
                            )
                        }

                    </div>
                </>)
            }

            {category === 'perHour' &&
                <div className="flex flex-col gap-4  p-4 text-white">
                    <div className="flex items-center gap-2">
                        <FaMapMarkerAlt className='font-bold text-[20px]' />
                        <span>{startLocation ? startLocation?.formatted_address : "Origin not specified"}</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <BsSliders2Vertical className='font-bold text-[20px]' />
                        <span>{t('booking_per_hour')}</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <GiAlarmClock className='font-bold text-[20px]' />
                        <span>{tripHour}</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <FaCalendarAlt className='font-bold text-[20px]' />
                        <span>{startDateTime ? formatDate(startDateTime) : "Date/Time not specified"}</span>
                    </div>
                    {
                        returnDateTime && (
                            <div className="flex items-center gap-2">
                                <FaCalendarAlt className='font-bold text-[20px]' />
                                <span>{startDateTime ? formatDate(returnDateTime) : "Date/Time not specified"}</span>
                            </div>
                        )
                    }

                </div>
            }

            {chosenCar.model &&
                <div className='flex flex-col  justify-center gap-2 p-6 text-white border-t-[1px] border-white'>
                    <h2 className='text-xl font-bold'>{t(`${chosenCar.category}`)}</h2>

                    <div className="flex justify-center  items-center mb-4">
                        <img src={chosenCar.image} alt={`${chosenCar.model}`} width={280} className="object-contain" />
                    </div>

                    <div className='flex justify-between items-center'>
                        <p className='text-[18px] font-[350]'>{t("transfer")}</p>
                        <p className='text-[18px] font-[350]'>CHF {totalCHF}</p>
                    </div>
                    <hr className='w-100 border-white' />
                    <div className='flex justify-between items-center '>
                        <p className='text-[18px] font-bold'>{t("in_total")}</p>
                        <div>
                            <p className='text-[18px] font-bold'>CHF <span className='text-[22px]'>{totalCHF == 0 ? 'On request' : totalCHF}</span></p>
                        </div>
                    </div>

                    <span className='text-[14px] font-[300] text-end opacity-75'>{t("no_hidden_costs")}</span>
                </div>
            }

            {passengerDetails.name &&
                <div className='flex flex-col justify-around  p-6 text-white  '>
                    <div className='border-t-2 pt-4 pb-4  flex flex-col gap-2'>
                        <p className='flex justify-start gap-2 items-center'>
                            <IoPeople className='w-[20px]' />{t('booking_passengers')}: {passengerDetails.passengers}
                        </p>
                        {passengerDetails.baggage != 0 &&
                            <p className='flex justify-start gap-2 items-center'>
                                <MdLuggage className='w-[20px]' />
                                {t('booking_baggage')}: {passengerDetails.baggage}</p>
                        }

                        {passengerDetails.childSeat != 0 &&
                            <p className='flex justify-start gap-2 items-center'>
                                <MdChildCare className='w-[20px]' />{t('booking_child_seat')}: {passengerDetails.childSeat}</p>
                        }

                        {passengerDetails.childSeatValues &&
                            passengerDetails.childSeatValues.map((seatData, index) => (
                                <p className='flex pl-6 font-[400] text-[14px] justify-start gap-2 items-center'><IoPeople className='w-[20px]' />#{index + 1} {t('booking_child_seat')} ${seatData} </p>
                            ))
                        }
                    </div>
                    <div className='border-t-2 pt-4 flex flex-col gap-2'>
                        <p className='flex justify-start gap-2 items-center'>
                            <AiFillSignature className='w-[20px]' />
                            {passengerDetails.name} {passengerDetails.lastName}
                        </p>
                        {passengerDetails.companyName &&
                            <p className='flex justify-start gap-2 items-center'>
                                <IoMdHome className='w-[20px]' />{passengerDetails.companyName}</p>
                        }
                        <p className='flex justify-start gap-2 items-center'>
                            <HiOutlineMailOpen className='w-[20px]' />
                            {passengerDetails.email}
                        </p>
                        <p className='flex justify-start gap-2 items-center'>
                            <FaPhoneAlt className='w-[20px]' />
                            {passengerDetails.phoneNo}
                        </p>
                        {passengerDetails.flightNumber &&
                            <p className='flex justify-start gap-2 items-center'>
                                <MdFlight className='w-[20px]' />{passengerDetails.flightNumber}</p>
                        }
                        {passengerDetails.nameBoard &&
                            <p className='flex justify-start gap-2 items-center'>
                                <SiNamesilo className='w-[20px]' />{passengerDetails.nameBoard}</p>
                        }
                        {passengerDetails.nameBoard &&
                            <p className='flex justify-start gap-2 items-center p-2'>{passengerDetails.notes}</p>
                        }
                    </div>
                </div>

            }
        </div>
    );
};

export default GoogleMapDirection;



