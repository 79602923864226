
import { useTranslation } from 'react-i18next';
import { FaStar, FaRegStar } from 'react-icons/fa';


const SingleReview = ({ review }) => {
    const { t } = useTranslation();
    return (
        <div className='flex flex-col gap-6 justify-center items-start min-w-[100%] md:min-w-[50%] lg:min-w-[32%] p-4 hover:text-[#da291c]'>
            <div className='flex gap-1'>
                {[...Array(5)].map((_, starIndex) => (
                    starIndex < review.rating ?
                        <FaStar
                            key={starIndex}
                            className='text-[24px] text-[#da291c]'
                        />
                        : <FaRegStar
                            key={starIndex}
                            className={`text-[24px] font-[300] text-[#da291c]`}
                        />


                ))}
            </div>
            <div className=' text-[16px] text-[#ffffff99] font-[300] '>
                {t(`${review.comment}`)}
            </div>

            <div className='flex flex-col items-start'>
                <span className='text-white font-bold text-[18px]'>
                    {t(`${review.name}`)}
                </span>
                <span className='text-[#da291c] text-[16px]'>
                    {t(`${review.location}`)}
                </span>
            </div>
        </div>
    )
}

export default SingleReview