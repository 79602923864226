import React, { useEffect } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

const FleetSingleCard = ({ car }) => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
            offset: 100,
        });
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };

    return (
        <div className='relative flex flex-col gap-2 items-center'>
            {/* Background Text behind each car image */}
            {/* <span
                className='block xl:hidden  absolute text-[65px] md:text-[60px] lg:text-[65px] text-gray-200 font-bold uppercase z-0 text-center'
                style={{ top: '50%', transform: 'translateY(-70%)', whiteSpace: 'nowrap' }}
            >
                helvetia
            </span> */}

            {/* Car Details */}
            <img width={45} src={car.logo} alt="mercedes logo" data-aos="fade-up" className='relative z-10' />
            <p className='text-gray-500 relative z-10' data-aos="fade-up">{car.brandName}</p>

            <img src={car.img} alt={car.class} className='w-[230px] h-[150px] object-contain  relative z-10' data-aos="fade-up" />
            <h3 className='text-[20px] tracking-[3px] mb-2 font-[450] relative z-10' data-aos="fade-up">{car.class}</h3>
            <div className='flex gap-10 justify-start items-center text-[14px] xl:text-[16px] relative z-10' data-aos="fade-up">
                <p>{t("from")} <span className='text-[#da291c]'>CHF {car.chf}/km</span></p>

                <div
                    onClick={() => scrollToTop()}
                    className='cursor-pointer text-[#da291c] flex items-center justify-start group w-[70px] xl:w-[90px]'
                >
                    <span className='hover:text-black uppercase text-[12px] xl:text-[14px]'>{t("book")}</span>
                    <FaArrowRight className='transition-all duration-300 group-hover:ml-2' />
                </div>
            </div>
        </div>
    );
}

export default FleetSingleCard;
