import React from 'react';
import BgImage from '../assets/images/caro/caro7.jpg';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

const Count = () => {
    const { t } = useTranslation();
    return (
        <div
            className="min-h-[100vh] bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center relative"
            style={{ backgroundImage: `linear-gradient(to bottom, rgba(36, 36, 36), rgba(36, 36, 36, 0.7), rgba(36, 36, 36)), url(${BgImage})` }}>

            {/* Main Content */}
            <div className="relative z-10 flex flex-col items-center text-white gap-6 pt-20">
                {/* Years of Experience Section */}
                <div className="flex items-end justify-center mb-10">
                    <CountUp
                        start={1}
                        end={10}
                        duration={8}
                        enableScrollSpy
                        scrollSpyOnce
                        className="text-[90px] sm:text-[130px] md:text-[150px] lg:text-[200px] 3xl:text-[300px] leading-snug font-[600]"
                    />
                    <span className="text-md md:text-lg font-semibold text-[#da291c] uppercase pl-4" dangerouslySetInnerHTML={{ __html: t("years_of_successful_work") }} /><br />
                </div>

                {/* Count Details */}
                <div className="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 gap-8 text-center text-white">
                    {/* Vehicles */}
                    <div>
                        <CountUp
                            start={1}
                            end={12}
                            duration={7}
                            enableScrollSpy
                            scrollSpyOnce
                            className="text-[45px] md:text-[40px] lg:text-[60px] font-bold"
                        />
                        <p className="text-sm lg:text-lg mt-2 uppercase">{t('vehicles')}</p>
                    </div>

                    {/* Destinations */}
                    <div>
                        <CountUp
                            start={1}
                            end={230}
                            duration={7}
                            enableScrollSpy
                            scrollSpyOnce
                            className="text-[45px] md:text-[40px] lg:text-[60px] font-bold"
                        />
                        <span className="text-[#da291c] text-[30px] font-bold ml-1">+</span>
                        <p className="text-sm lg:text-lg mt-2 uppercase">{t('destinations')}</p>
                    </div>

                    {/* Reservations */}
                    <div>
                        <CountUp
                            start={1}
                            end={10580}
                            duration={7}
                            enableScrollSpy
                            scrollSpyOnce
                            className="text-[45px] md:text-[40px] lg:text-[60px] font-bold"
                        />
                        <span className="text-[#da291c] text-[30px] font-bold ml-1">+</span>
                        <p className="text-sm lg:text-lg mt-2 uppercase">{t('reservations')}</p>
                    </div>

                    {/* Customer Satisfaction */}
                    <div>
                        <CountUp
                            start={1}
                            end={100}
                            duration={7}
                            enableScrollSpy
                            scrollSpyOnce
                            className="text-[45px] md:text-[40px] lg:text-[60px] font-bold"
                        />
                        <span className="text-[#da291c] text-[30px] font-bold ml-1">%</span>
                        <p className="text-sm lg:text-lg mt-2 uppercase">{t('customer_satisfaction')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Count;