import React from 'react'
import { IoIosPeople } from "react-icons/io";
import { BsFillLuggageFill } from "react-icons/bs";
import { RiLuggageDepositFill } from "react-icons/ri";
import { useTranslation } from 'react-i18next';

const Vehicle = ({ car, bookingDistance, nextStep, setChoseCar, setTotalPrice, bookingData }) => {
    const { t } = useTranslation();
    const { category, model, capacity, image, features, price, minCHF } = car;
    let totalCHF = 0;

    const totalDistance = parseFloat(bookingDistance);

    if (totalDistance <= 20) {
        totalCHF = minCHF;
        bookingData?.tripType === 'roundTrip' ? totalCHF = totalCHF * 2 : totalCHF = totalCHF;

    } else {
        const extraKm = totalDistance - 20;
        totalCHF = minCHF + (price * extraKm);
        totalCHF = Math.round(totalCHF * 100) / 100;
        // tripType && tripType === 'roundTrip' ? totalCHF = totalCHF * 2 : totalCHF = totalCHF;
        bookingData?.tripType === 'roundTrip' ? totalCHF = totalCHF * 2 : totalCHF = totalCHF;
        // totalCHF = Math.round(totalCHF);
    }

    const handleChooseClick = () => {
        nextStep();
        setChoseCar(car)
        setTotalPrice(totalCHF)
    }
    return (
        <div className="flex flex-col lg:flex-row gap-4  items-center bg-white border-b-2 border-red-100 pb-10">
            <div className="flex flex-col justify-center">
                {/* Header */}
                <div className="mb-4">
                    <h3 className="text-2xl font-semibold text-[#111] tracking-wide">{t(`${category}`)}</h3>
                    <p className="text-sm text-gray-500">e.g. {t(`${model}`)}</p>
                </div>
                <img src={image} alt={`${model}`} width={300} className="object-contain" />
                <ul className="text-[14px] text-[#777] w-[90%]">
                    {features.map((feature, index) => (
                        <li key={index} className="flex items-center">
                            <span className="mr-2 text-lg text-[#da291c]">›</span> {/* Custom Icon */}
                            {t(`${feature}`)}
                        </li>
                    ))}
                </ul>
            </div>


            <div className="flex flex-col items-center">
                {/* Capacity Information */}
                <div className="space-y-2  mb-6 border-l-0 lg:border-l-2 border-red-100 pl-0 lg:pl-6">
                    <p className="text-sm text-[#777] flex items-center">
                        <span className="text-[#da291c] mr-2 w-100"><IoIosPeople size={20} /></span>
                        {t("maxPassengers")}: {category === 'bookDetCategory3' ? '7-8' : capacity.passengers}
                    </p>
                    <p className="text-sm text-[#777] flex items-center">
                        <span className="text-[#da291c] mr-2 w-100"><BsFillLuggageFill size={20} /></span>
                        {t("maxLuggage")}: {capacity.luggage}
                    </p>
                    <p className="text-sm text-[#777] flex items-center">
                        <span className="text-[#da291c] mr-2 w-100"><RiLuggageDepositFill size={20} /></span>
                        {t("maxHandLuggage")}: {capacity.handLuggage}
                    </p>
                </div>

                {/* Price and Button */}
                <div className="flex flex-col items-center">
                    <p className="mb-2 text-gray-800">
                        CHF <span className={`${price == 0 ? 'text-[22px]' : 'text-[34px]'} font-bold text-black`}>{price == 0 ? 'On request' : totalCHF}</span>
                    </p>
                    <button className="bg-[#da291c] uppercase hover:bg-black  text-white tracking-[2px] py-2 px-10" onClick={handleChooseClick}>
                        {t("choose")}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Vehicle