import React from 'react';
import { useTranslation } from 'react-i18next';
import { mostPopularRoutes } from '../utils/helper';
import { BiRightArrowAlt } from 'react-icons/bi';

const MostPopularRoutes = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-[#242424] flex flex-col justify-center items-center pb-20 pt-0 lg:pt-15 px-4">
      <h2 className="text-2xl md:text-4xl font-semibold text-center text-white uppercase mb-8" dangerouslySetInnerHTML={{ __html: t("most_popular_routes") }} />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-10">
        {mostPopularRoutes.map((route, index) => (
          <div
            key={index}
            className="group bg-gray-100 hover:bg-[#da291c] hover:text-white cursor-pointer p-4 rounded-lg flex flex-col justify-between items-center w-[90vw] md:w-[45vw] lg:w-[22vw] xl:w-[20vw] 3xl:w-[18vw] shadow-md"
          >
            <div className="flex items-center mb-2">
              <span className="font-medium text-md">{route.from}</span>
              <BiRightArrowAlt className="mx-2 " />
              <span className="font-medium text-md">{route.to}</span>
            </div>
            {/* Use group-hover:bg-black to change the button color on hover */}
            <button className="bg-white group-hover:bg-black group-hover:text-white text-[12px] font-medium py-1 px-2 rounded-full border border-gray-300 shadow-sm w-[40vw] md:w-[30vw] lg:w-[20vw] xl:w-[10vw]">
              {route.duration} | {route.distance}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MostPopularRoutes;
