import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';

const CustomDropdown = ({ options, value, setTripTypeOrHour }) => {
    const [isOpen, setIsOpen] = useState(false); // State to handle dropdown open/close
    const [selectedValue, setSelectedValue] = useState(value); // State to handle the selected value
    const dropdownRef = useRef(); // Reference for the dropdown element
    const handleToggle = (event) => {
        event.preventDefault();
        setIsOpen(!isOpen);
    };

    const handleSelect = (option) => {
        setSelectedValue(option.value);
        setTripTypeOrHour(option.value); // Update the parent component
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectedLabel = options.find((option) => option.value === value)?.label;

    const truncateLabel = (label, charLimit = 10) => {
        if (!label) return '';
        return label.length > charLimit ? label.slice(0, charLimit) + '...' : label;
    };

    return (
        <div className="relative w-full" ref={dropdownRef}>
            <button
                type="button"
                onClick={handleToggle}
                className="w-full py-3 px-4 flex items-center justify-between border border-gray-600 rounded text-white focus:outline-none"
                aria-expanded={isOpen}
            >
                <span className="flex items-center">
                    {truncateLabel(selectedLabel)}
                </span>
                {isOpen ? <AiOutlineUp className="ml-2" /> : <AiOutlineDown className="ml-2" />}
            </button>
            {isOpen && (
                <ul className="absolute z-20 mt-1 bg-white text-black border border-gray-300 rounded shadow-lg w-[200px] md:w-[200px] max-h-80 overflow-y-auto">
                    {options.map((option) => (
                        <li
                            key={option.value}
                            onClick={() => handleSelect(option)}
                            className={`cursor-pointer px-4 py-2 flex items-center border-b-2 ${option.value === selectedValue ? 'bg-[#da291c] text-white' : 'hover:bg-[#da291c] hover:text-white'
                                }`}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomDropdown;
