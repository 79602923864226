import React, { useState, useRef, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete } from "@react-google-maps/api";
import CustomDropdown from "./CustomDropdown";
import { useNavigate } from "react-router-dom";

const SliderForm = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isBookingPerKm, setIsBookingPerKm] = useState(true); // State for Booking Per KM
  const [isBookingPerHour, setIsBookingPerHour] = useState(false); // State for Booking Per Hour
  const [pickupLocation, setPickupLocation] = useState(null);
  const [dropoffLocation, setDropoffLocation] = useState(null);
  const [startLocation, setStartLocation] = useState(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false); // State for script load status
  const [tripType, setTripType] = useState("oneWay"); // State for Trip Type
  const [startDateTime, setStartDateTime] = useState(""); // State for Start Date and Time
  const [tripHour, setTripHour] = useState(t("1_hour")); // State for Trip Hour
  const [returnDateTime, setReturnDateTime] = useState(""); // State for Return Date and Time
  const [minReturnDateTime, setMinReturnDateTime] = useState(""); // State for minimum return date and time


  const europeBounds = {
    north: 71.185,   // Northern Europe
    south: 34.5,     // Southern Europe (Mediterranean)
    west: -25.0,     // Western edge (Portugal)
    east: 45.0       // Eastern Europe (Russia, Ukraine)
  };
  

  const pickupRef = useRef();
  const dropoffRef = useRef();
  const startLocationRef = useRef();

  const handlePlaceChanged = useCallback(() => {
    const place = pickupRef.current.getPlace();
    setPickupLocation(place);
  }, []);

  const handleDropoffChanged = useCallback(() => {
    const place = dropoffRef.current.getPlace();
    setDropoffLocation(place);
  }, []);

  const handleStartLocationChanged = useCallback(() => {
    const place = startLocationRef.current.getPlace();
    setStartLocation(place);
  }, []);

  useEffect(() => {
    setTripHour(t("1_hour"));

  }, [i18n.language]);

  const resetLocations = useCallback(() => {
    setPickupLocation(null);
    setDropoffLocation(null);
    setStartLocation(null);
    setTripType("oneWay");
    setStartDateTime("");
    setTripHour(t("1_hour"));
    setMinReturnDateTime("");
    setReturnDateTime("");
    if (pickupRef.current) pickupRef.current.value = "";
    if (dropoffRef.current) dropoffRef.current.value = "";
    if (startLocationRef.current) startLocationRef.current.value = "";
  }, []);

  const handleToggleBookingType = (bookingType) => {
    if (bookingType === "perKm") {
      setIsBookingPerKm(true);
      setIsBookingPerHour(false);
    } else if (bookingType === "perHour") {
      setIsBookingPerKm(false);
      setIsBookingPerHour(true);
    }
    resetLocations();
  };

  const handleStartDateTimeChange = (event) => {
    setStartDateTime(event.target.value);
  };

  const handleReturnDateTimeChange = (event) => {
    setReturnDateTime(event.target.value);
  };

  useEffect(() => {
    const checkIfScriptLoaded = () => {
      if (window.google && window.google.maps) {
        setIsScriptLoaded(true);
      } else {
        setIsScriptLoaded(false);
      }
    };
    checkIfScriptLoaded();
  }, []);

  const handleBooking = (event) => {
    event.preventDefault();

    // Initialize a flag to check if all properties are valid
    let isValid = true;

    if (isBookingPerKm) {
      // Create an object to store booking data
      const bookingData = {
        pickupLocation,
        dropoffLocation,
        startDateTime,
        tripType,
        category: "perKm"
      };

      // Check if any required properties are missing or empty
      if (!pickupLocation || !dropoffLocation || !startDateTime || !tripType) {
        isValid = false;
      }

      // Conditionally add returnDateTime if tripType is "roundTrip"
      if (tripType === "roundTrip") {
        if (!returnDateTime) {
          isValid = false;
        } else {
          bookingData.returnDateTime = returnDateTime;
        }
      }

      if (isValid) {
        sessionStorage.setItem("bookingData", JSON.stringify(bookingData));
      }
    } else if (isBookingPerHour) {
      // Check if any required properties for per hour booking are missing or empty
      if (!startLocation || !startDateTime || !tripHour) {
        isValid = false;
      } else {
        sessionStorage.setItem("bookingData", JSON.stringify({
          startLocation,
          startDateTime,
          tripHour,
          category: "perHour"
        }));
      }
    }

    // Navigate to the booking route only if all required properties are present
    if (isValid) {
      navigate(`/${t("booking")}`);
    } else {
      // Provide feedback to the user (e.g., alert or display a message)
      alert("Please fill in all required fields before proceeding.");
    }
  };

  return (
    <div className="lg:h-[500px] h-[450px] max-w-md mx-auto mt-[-100px] md:mt-0 lg:mt-24 p-3">
      <h2
        className="text-[22px] text-white font-bold mb-6 text-center"
        dangerouslySetInnerHTML={{ __html: t("book_a_transfer") }}
      />

      <div className="flex justify-center gap-2 mb-6">
        <button
          className={`w-1/2 py-2 text-white border font-semibold ${isBookingPerKm
            ? "bg-[#da291c] border-[#da291c]"
            : "bg-transparent border-[#da291c]"
            }`}
          onClick={() => handleToggleBookingType("perKm")}
        >
          {t("booking_per_km")}
        </button>
        <button
          className={`w-1/2 py-2 text-white border font-semibold ${isBookingPerHour
            ? "bg-[#da291c] border-[#da291c]"
            : "bg-transparent border-[#da291c]"
            }`}
          onClick={() => handleToggleBookingType("perHour")}
        >
          {t("booking_per_hour")}
        </button>
      </div>

      <form className="p-8 bg-black bg-opacity-75 rounded-lg shadow-lg" onSubmit={handleBooking}>
        {isBookingPerKm && (
          <>
            {/* Booking Per KM Form */}
            <div className="mb-4">
              {isScriptLoaded ? (
                <Autocomplete
                  onLoad={(autocomplete) => (pickupRef.current = autocomplete)}
                  onPlaceChanged={handlePlaceChanged}
                  options={{
                    bounds: europeBounds,  // Set the bounds to cover Europe
                    strictBounds: true     // Restrict the search to within these bounds
                  }}
                
                >
                  <input
                    type="text"
                    placeholder={t("pick_up")}
                    className="w-full py-3 px-4 bg-transparent border border-gray-600 rounded text-white"
                  />
                </Autocomplete>
              ) : (
                <input
                  type="text"
                  placeholder={t("pick_up")}
                  className="w-full py-3 px-4 bg-transparent border border-gray-600 rounded text-white"
                />
              )}
            </div>
            <div className="mb-4">
              {isScriptLoaded ? (
                <Autocomplete
                  onLoad={(autocomplete) => (dropoffRef.current = autocomplete)}
                  onPlaceChanged={handleDropoffChanged}
                  options={{
                    bounds: europeBounds,  // Set the bounds to cover Europe
                    strictBounds: true     // Restrict the search to within these bounds
                  }}
                >
                  <input
                    type="text"
                    placeholder={t("drop_off")}
                    className="w-full py-3 px-4 bg-transparent border border-gray-600 rounded text-white"
                  />
                </Autocomplete>
              ) : (
                <input
                  type="text"
                  placeholder={t("drop_off")}
                  className="w-full py-3 px-4 bg-transparent border border-gray-600 rounded text-white"
                />
              )}
            </div>

            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0  md:space-x-4 mb-4">
              <div className="relative w-[100%] md:w-[50%]">
                {/* Placeholder text positioned absolutely on top of the input */}
                <div className={`${startDateTime ? "hidden" : "block"} lg:hidden absolute top-4 left-1 w-full pointer-events-none`}>
                  <p className="text-sm  text-white px-1">mm/dd/yyyy, --:-- --</p>
                </div>

                {/* Input field */}
                <input
                  type="datetime-local"
                  id="datetime"
                  className="w-full min-h-[50px] text-sm py-3 px-1 bg-transparent border border-gray-600 rounded text-white focus:outline-none focus:border-white"
                  style={{ colorScheme: "dark", appearance: "none" }}
                  onChange={handleStartDateTimeChange} // Handle start date change
                />
              </div>

              <div className="w-[100%] md:w-[50%]">
                <label className="sr-only" htmlFor="tripType">
                  {t("trip_type")}
                </label>

                {/* Custom Dropdown */}
                <CustomDropdown
                  options={[
                    { value: "oneWay", label: t("one_way") },
                    { value: "roundTrip", label: t("round_trip") },
                  ]}
                  value={tripType}
                  setTripTypeOrHour={setTripType}
                />
              </div>
            </div>


            {/* Conditionally render the return date input if "two_way" is selected */}
            {tripType === "roundTrip" && (
              <div className="w-[100%] md:w-[50%] mb-4 relative">
                <div className={`${returnDateTime ? "hidden" : "block"} lg:hidden absolute top-4 left-1 w-full pointer-events-none`}>
                  <p className="text-sm  text-white px-1">mm/dd/yyyy, --:-- --</p>
                </div>
                <input
                  type="datetime-local"
                  id="returnDate"
                  className="w-full min-h-[50px]  text-sm py-3 px-1 bg-transparent border border-gray-600 rounded text-white focus:outline-none focus:border-white"
                  style={{ colorScheme: "dark", appearance: "none" }}
                  min={startDateTime} // Set minimum to start date and time + duration
                  onChange={handleReturnDateTimeChange} // Handle return date change
                />
              </div>
            )}
          </>
        )}

        {isBookingPerHour && (
          <>
            {/* Booking Per Hour Form */}
            <div className="mb-4">
              {isScriptLoaded ? (
                <Autocomplete
                  onLoad={(autocomplete) =>(startLocationRef.current = autocomplete)}
                  onPlaceChanged={handleStartLocationChanged}
                  options={{
                    bounds: europeBounds,  // Set the bounds to cover Europe
                    strictBounds: true     // Restrict the search to within these bounds
                  }}
                >
                  <input
                    type="text"
                    placeholder={t("start_location")}
                    className="w-full py-3 px-4 bg-transparent border border-gray-600 rounded text-white"
                  />
                </Autocomplete>
              ) : (
                <input
                  type="text"
                  placeholder={t("start_location")}
                  className="w-full py-3 px-4 bg-transparent border border-gray-600 rounded text-white"
                />
              )}
            </div>
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mb-6">
              <div className="w-[100%] md:w-[50%] relative">
                <div className={`${startDateTime ? "hidden" : "block"} lg:hidden absolute top-4 left-1 w-full pointer-events-none`}>
                  <p className="text-sm  text-white px-1">mm/dd/yyyy, --:-- --</p>
                </div>
                <input
                  type="datetime-local"
                  id="datetime"
                  className="w-full min-h-[50px] text-sm py-3 px-1 bg-transparent border border-gray-600 rounded text-white focus:outline-none focus:border-white"
                  style={{ colorScheme: "dark", appearance: "none" }}
                  value={startDateTime}
                  onChange={handleStartDateTimeChange} // Handle start date change
                />
              </div>
              <div className="w-[100%] md:w-[50%]">
                <label className="sr-only" htmlFor="hours">
                  {t("hours")}
                </label>
                <CustomDropdown
                  options={[
                    { value: t("1_hour"), label: t("1_hour") },
                    { value: t("2_hours"), label: t("2_hours") },
                    { value: t("3_hours"), label: t("3_hours") },
                    { value: t("4_hours"), label: t("4_hours") },
                    { value: t("5_hours"), label: t("5_hours") },
                    { value: t("6_hours"), label: t("6_hours") },
                    { value: t("7_hours"), label: t("7_hours") },
                    { value: t("8_hours"), label: t("8_hours") },
                    { value: t("9_hours"), label: t("9_hours") },
                    { value: t("10_hours"), label: t("10_hours") },
                  ]}
                  value={tripHour}
                  setTripTypeOrHour={setTripHour}
                />
              </div>
            </div>
          </>
        )}

        <button
          type="submit"
          className="w-full py-3 text-black hover:text-white bg-[#ffffff] hover:bg-[#da291c] font-bold rounded uppercase"
        >
          {t("next")}
        </button>
      </form>
    </div>
  );
};

export default SliderForm;
