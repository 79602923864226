import React from 'react'
import { useTranslation } from 'react-i18next';
import { FaClock, FaEnvelope, FaHome, FaInstagram, FaPhoneAlt } from 'react-icons/fa'

const ContactInformation = () => {
  const { t } = useTranslation();


  const handleOpenMap1 = () => {
    window.open('https://www.google.com/maps/place/Paradeplatz/@47.3696586,8.5390371,16z/data=!4m6!3m5!1s0x47900b897d578ef9:0xbf0174f3d98a4b76!8m2!3d47.3697508!4d8.538908!16s%2Fm%2F043k5vx?hl=de-ch&entry=ttu&g_ep=EgoyMDI0MDkwOC4wIKXMDSoASAFQAw%3D%3D', '_blank');
  };
  const handleOpenMap2 = () => {
    window.open('https://www.google.com/maps/place/Terminal+1,+Flughafenstrasse,+8302+Kloten,+Schweiz/@47.4525581,8.558885,17z/data=!3m1!4b1!4m6!3m5!1s0x4790755ae4880ddd:0x5d1c9db25f5668d3!8m2!3d47.4525581!4d8.5614599!16s%2Fg%2F1hc4dfqpp?hl=de-ch&entry=ttu&g_ep=EgoyMDI0MDkwOC4wIKXMDSoASAFQAw%3D%3D', '_blank');
  };
  const handleCopyNumber = () => {
    const phoneNumber = '+41 78 867 90 93';
    navigator.clipboard.writeText(phoneNumber).then(() => {
      alert('Phone number copied to clipboard!');
    });
  };
  return (
    <div className="w-full md:w-[35%] xl:w-[25%] bg-black pt-[5vh] pl-[3vh] pb-[5vh] shadow-md text-white">
      {/* Contact Information Section */}
      <div className="mb-8">
        <h2 className="text-md mb-4 uppercase">{t("contact")}</h2>
        <div className="flex items-center mb-4 mt-[30px]">
          <FaHome className="mr-3" />
          <div className='cursor-pointer'>
            <p onClick={handleOpenMap1} className="text-white  cursor-pointer">{t("location1")}</p>
          </div>
        </div>
        <div className="flex items-center mb-4 ">
          <FaHome className="mr-3" />
          <div className='cursor-pointer'>
            <p onClick={handleOpenMap2} className="text-white  cursor-pointer">{t("location2")}</p>
          </div>
        </div>
        <div className="flex items-center mb-4">
          <FaPhoneAlt className=" mr-3" />
          <p onClick={handleCopyNumber} className='cursor-pointer' >+41 78 867 90 93</p>
        </div>
        <div className="flex items-center mb-8">
          <FaEnvelope className=" mr-3" />
          <a href="mailto:info@helvetialimousine.ch">
            info@helvetialimousine.ch
          </a>
        </div>
      </div>

      {/* Working Hours Section */}
      <div className="mb-8 mt-[5vh]">
        <h2 className="text-md mb-4 uppercase">{t("working_hours")}</h2>
        <div className="flex items-center">
          <FaClock className=" mr-3" />
          <p className='uppercase'>24/7 {t("service")}</p>
        </div>
      </div>

      {/* Social Media Section */}
      <div className='mt-[5vh]'>
        <h2 className="text-md mb-4">{t("social_networks")}</h2>
        <div className="flex space-x-6 mt-[3vh]">
          <a href="https://www.instagram.com/helvetialimousine/"
            target="_blank"
            rel="noopener noreferrer" className="w-[30px] h-[30px] flex items-center justify-center border border-white hover:bg-[#da291c] ">
            <FaInstagram className="text-xl" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContactInformation