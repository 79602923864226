import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../assets/images/helvetia.png';
import ROUTES_CONFIG from '../routesConfig';
import { FaInstagram } from 'react-icons/fa';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language; // Get the current language ('en' or 'de')
  const routes = ROUTES_CONFIG[currentLanguage]; // Get the route paths for the current language

  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  // Function to handle scroll detection
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    sessionStorage.removeItem('bookingData');
  }, []);

  // Filter out dynamic routes that shouldn't appear in the navbar
  const staticRoutes = Object.entries(routes).filter(
    ([key]) => !['sliderDetails', 'singleBlog', 'legal_notice', 'booking', 'term'].includes(key)
  );

  const handleCopyNumber = () => {
    const phoneNumber = '+41 78 867 90 93';
    navigator.clipboard.writeText(phoneNumber).then(() => {
      alert('Phone number copied to clipboard!');
    });
  };

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng); // Change language using i18n
    localStorage.setItem('language', lng); // Save selected language to localStorage
  };

  return (
    <nav
      className={`sticky top-0 z-50 border-gray-200 h-[100px] duration-300 ${scrolled ? 'bg-black' : 'bg-black lg:bg-transparent'}`}
    >
      <div className="max-w-screen-xl flex items-center justify-between lg:justify-end mx-auto">
        {scrolled && (
          <Link to={routes.home} className='hidden lg:block xl:w-[300px]' >
            <img
              src={Logo}
              className="w-[150px] md:w-[150px]"
              alt="Helvetia Limousine"
            />
          </Link>
        )}
        <Link className='mt-3 lg:hidden' to={routes.home}>
          <img
            src={Logo}
            className="w-[150px] md:w-[150px]"
            alt="Helvetia Limousine"
          />
        </Link>

        {/* Mobile and Tablet menu button */}
        <div className='flex justify-between items-center gap-4 mt-3 lg:hidden w-[120px] md:w-[150px]'>
          {/* <div className='lg:hidden block w-[30px] h-[20px] bg-red-400'> */}
          <div
            // value={currentLanguage}
            // onClick={(e) => handleLanguageChange(e.target.value)}
            className="flex gap-4 "
          >
            <a onClick={(e) => handleLanguageChange('en')} value="en" className={`${currentLanguage == 'en' ? 'text-red-500' : 'text-white'}`}>En</a>
            <hr className='w-[1px] h-[30px] bg-white' />
            <a onClick={(e) => handleLanguageChange('de')} value="de" className={`${currentLanguage == 'de' ? 'text-red-500' : 'text-white'}`}>De</a>

          </div>
          {/* </div> */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="inline-flex items-center w-10 h-10 justify-center text-sm text-white rounded-lg  hover:bg-gray-100 focus:outline-none"
            aria-controls="navbar-default"
            aria-expanded={isOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>

        {/* Mobile and Tablet menu */}
        <div
          className={`w-full lg:hidden fixed top-0 left-0 bg-black text-white h-full transition-transform ${isOpen ? 'transform translate-x-0' : 'transform -translate-x-full'}`}
          id="navbar-default"
        >
          {/* Close button for mobile and tablet menu */}

          <div className='flex justify-between '>
            <Link className='mt-3 lg:hidden' to={routes.home}>
              <img
                src={Logo}
                className="w-[150px] md:w-[150px]"
                alt="Helvetia Limousine"
              />
            </Link>
            <button
              onClick={() => setIsOpen(false)}
              className="mr-3 text-white text-4xl"
            >
              <span className="sr-only">Close menu</span>
              &times;
            </button>
          </div>
          <hr className='w-[98%] mx-auto  border-[#da291c]' />
          <ul className="flex flex-col items-start space-y-2 pt-4 ml-2">
            {staticRoutes.map(([key, path]) => (
              <li key={key}>
                <Link
                  to={path}
                  className={`block py-2 px-3 rounded ${location.pathname === path ? 'text-[#da291c]' : 'text-white'} hover:text-[#da291c']`}
                  onClick={() => setIsOpen(false)}
                >
                  {t(key)}
                </Link>
              </li>
            ))}
          </ul>
          <div className="flex my-5 mx-5">
            <a
              href="https://www.instagram.com/helvetialimousine/" // Replace with your Facebook profile URL
              target="_blank"
              rel="noopener noreferrer"
              className='text-[25px] hover:text-[#da291c]'
            >
              <FaInstagram />

            </a>
          </div>
        </div>

        {/* Desktop menu */}
        <div className="hidden lg:flex w-full lg:w-auto h-[100px]" id="navbar-default">
          <ul className="font-medium flex flex-col lg:flex-row items-center p-4 mr-0 lg:mr-4 lg:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 lg:space-x-8 rtl:space-x-reverse lg:mt-0 lg:border-0 lg:bg-transparent">
            <li className="mr-[130px] lg:mr-[60px] xl:mr-[130px] cursor-pointer hover:bg-red-700 p-2 rounded-md ">
              <h3
                onClick={handleCopyNumber}
                className={`block py-2 px-3 rounded lg:border-0 text-white lg:p-0 `}
              >
                +41 78 867 90 93
              </h3>
            </li>
            {staticRoutes.map(([key, path]) => (
              <li key={key}>
                <Link
                  to={path}
                  className={`block py-2 px-3 rounded lg:border-0 lg:p-0 ${location.pathname === path ? 'text-[#da291c]' : 'text-white'} hover:text-[#da291c'] uppercase`}
                >
                  {t(key)}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
