import React, { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import { services } from '../utils/helper';
const LeadingSlider = () => {
    const { t } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsToShow, setItemsToShow] = useState(3); // Adjust based on screen size
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        AOS.init({
            duration: 1000,
            offset: 100,
        });

        // Update itemsToShow based on screen size
        const updateItemsToShow = () => {
            if (window.innerWidth >= 1024) {
                setItemsToShow(3); // Large screens
            } else if (window.innerWidth >= 768) {
                setItemsToShow(2); // Medium screens
            } else {
                setItemsToShow(1); // Small screens
            }
        };

        window.addEventListener('resize', updateItemsToShow);
        updateItemsToShow(); // Call initially

        return () => {
            window.removeEventListener('resize', updateItemsToShow);
        };
    }, []);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex + itemsToShow < services.length ? prevIndex + itemsToShow : 0
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex - itemsToShow >= 0 ? prevIndex - itemsToShow : services.length - itemsToShow
        );
    };

    const handleRedirect = (uniqueKey) => {

        navigate(`/${t("service")}/${uniqueKey}`);
    };

    return (
        <div className='w-full pb-10 flex flex-col items-center bg-[#f1f1f1] pt-20'>
            <span className='text-[28px] xl:text-[40px] lg:text-[36px] font-[600] mb-2 text-center uppercase p-2 mt-8 xl:mt-0' data-aos="fade-up" dangerouslySetInnerHTML={{ __html: t("leading_limousine_service") }} />

            {/* Slider */}
            <div className='w-full max-w-[1200px] overflow-hidden p-2' data-aos="fade-up">
                {/* Prev & Next Button */}
                <div className='flex justify-end gap-3 items-center mt-4 text-[600]'>
                    <button
                        disabled={currentIndex === 0}
                        onClick={prevSlide}
                        className='hover:bg-[#da291c] p-2 border-2 border-[#da291c]  transition disabled:hover:bg-transparent disabled:border-gray-400'>
                        <IoIosArrowBack />
                    </button>
                    <button
                        disabled={currentIndex + itemsToShow >= services.length}
                        onClick={nextSlide}
                        className='hover:bg-[#da291c] p-2 border-2 border-[#da291c] transition disabled:hover:bg-transparent disabled:border-gray-400'>
                        <IoIosArrowForward />
                    </button>
                </div>

                {/* Slider */}
                <div className='flex transition-transform duration-500 ease-out'
                    style={{ transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)` }}>
                    {services.map((service, index) => (
                        <div key={index} className='min-w-[100%] md:min-w-[50%] lg:min-w-[33.33%] p-4 cursor-pointer group'
                            onClick={() => handleRedirect(service.uniqueKey)}>
                            <div className='overflow-hidden rounded-lg'>
                                <img src={service.image} alt={service.title} className='' />
                                <div className='bg-white h-[90px] flex justify-center group-hover:bg-[#da291c] items-center px-5 '>
                                    <p className='text-center group-hover:text-white font-semibold'>{t(`${service.title}`)}</p>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LeadingSlider;
