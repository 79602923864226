import 'react-phone-number-input/style.css';
import React, { useEffect, useState } from 'react';
import { AiFillSignature } from "react-icons/ai";
import { IoMdHome } from "react-icons/io";
import { HiOutlineMailOpen } from "react-icons/hi";
import { MdFlight } from "react-icons/md";
import { SiNamesilo } from "react-icons/si";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import CustomDropDownOptions from './CustomDropDownOptions';
import { useTranslation } from 'react-i18next';

const PassengerDetailsForm = ({ chosenCar, setPassengerDetails, nextStep }) => {
    const { t } = useTranslation();
    const [phoneNo, setPhoneNo] = useState('');
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [passengers, setPassengers] = useState(1);
    const [baggage, setBaggage] = useState(0);
    const [childSeat, setChildSeat] = useState(0);
    const [childSeatValues, setChildSeatValues] = useState([]); // Array to manage dynamic states for child seats
    const [flightNumber, setFlightNumber] = useState("");
    const [nameBoard, setNameBoard] = useState("");
    const [notes, setNotes] = useState("");
    const [error, setError] = useState(false);
    const [childSeatError, setChildSeatError] = useState(false);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setError(!emailRegex.test(e.target.value));
    };

    useEffect(() => {
        // Initialize child seat values
        setChildSeat(0);
        setChildSeatValues([]);
    }, [passengers]);

    const handlePaymentClick = (e) => {
        e.preventDefault();
        if (!isValidPhoneNumber(phoneNo)) {
            alert("Invalid phone number");
            return;
        }

        // Validate that the number of child seat values matches the number of child seats
        if (childSeatValues.length !== Number(childSeat) || childSeatValues.some(seat => seat === "choose")) {
            setChildSeatError(true);
            alert("Please select valid options for all child seats.");
            return;
        }

        // Reset error if no invalid child seats
        setChildSeatError(false);

        // Creating the object with all the form data
        const formData = {
            name,
            lastName,
            companyName,
            email,
            phoneNo,
            passengers,
            baggage,
            childSeat,
            childSeatValues, // Send the values of each child seat selection
            flightNumber,
            nameBoard,
            notes
        };  // or send this object to your backend
        nextStep()

        setPassengerDetails(formData)
        // Now you can proceed with the payment
        // e.g., navigate to the payment page or handle payment process
    };

    // Destructure capacity from chosenCar
    const { capacity } = chosenCar;


    // Generate passenger options dynamically based on the capacity
    const passengerOptions = Array.from({ length: capacity.passengers }, (_, i) => ({
        value: (i + 1).toString(),
        label: `${i + 1}`,
    }));

    // Generate baggage options (including "0" for no baggage)
    const baggageOptions = Array.from({ length: capacity.luggage + 1 }, (_, i) => ({
        value: i.toString(),
        label: `${i}`,
    }));

    // Generate child seat options (including "0" for no child seat)
    const childOptions = Array.from({ length: passengers }, (_, i) => ({
        value: i.toString(),
        label: `${i}`,
    }));

    const childSeatOptions = [
        { value: `${t('booking_drop_choose')}`, label: `${t('booking_drop_choose')}` },
        { value: "0 - 12 kg", label: "0 - 12 kg" },
        { value: "9 - 36 kg", label: "9 - 36 kg" },
        { value: "15 - 36 kg", label: "15 - 36 kg" },
    ];

    // Handle updating individual child seat dropdowns
    const handleChildSeatChange = (value, index) => {
        const updatedChildSeats = [...childSeatValues];
        updatedChildSeats[index] = value;
        setChildSeatValues(updatedChildSeats);
    };
    return (
        <form className="p-2 md:p-8 pt-0" onSubmit={(e) => handlePaymentClick(e)}>
            {/* Passenger Details Section */}
            <div className="mb-8">
                <h2 className="font-semibold text-xl mb-4">{t("passenger_Details")}</h2>

                <div className="flex flex-col md:flex-row gap-4 mb-4">
                    {/* Name and Last Name */}
                    <div className="flex-1 relative">
                        <AiFillSignature className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                        <input
                            type="text"
                            placeholder={`${t("first_name")}*`}
                            className="w-full border-[1px] border-black p-2 pl-10 outline-none rounded-sm"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="flex-1 relative">
                        <AiFillSignature className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                        <input
                            type="text"
                            placeholder={`${t("last_name")}*`}
                            className="w-full border-[1px] border-black p-2 pl-10 outline-none rounded-sm"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </div>
                </div>

                {/* Company Name */}
                <div className="flex-1 mb-4 relative">
                    <IoMdHome className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                        type="text"
                        placeholder={`${t("company_name")}`}
                        className="w-full border-[1px] border-black p-2 pl-10 outline-none rounded-sm"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                </div>

                {/* Email */}
                <div className="flex-1 mb-4">
                    <div className='relative'>
                        <HiOutlineMailOpen className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                        <input
                            type="email"
                            placeholder="E-mail*"
                            value={email}
                            onChange={handleEmailChange}
                            className={`w-full border-[1px] ${error ? 'border-red-500' : 'border-black'} p-2 pl-10 outline-none rounded-sm`}
                            required
                        />
                    </div>
                </div>

                {/* Phone with Country Code */}
                <div className="border-[1px] border-black outline-none p-2">
                    <PhoneInput
                        international
                        defaultCountry="CH"
                        countryCallingCodeEditable={false}
                        value={phoneNo}
                        onChange={setPhoneNo}
                        required
                    />
                </div>
            </div>

            {/* Options Section */}
            <div className="mb-8">
                <h2 className="font-semibold text-xl mb-4">{t("booking_options")}</h2>

                <div className="flex flex-wrap gap-4 mb-4">
                    {/* Passengers */}
                    <div className='flex flex-col md:flex-row gap-2 justify-center items-center w-full'>
                        <div className="flex items-start flex-col flex-1 w-100">
                            <span className="text-sm md:text-[14px]">{t("booking_passengers")}</span>
                            <CustomDropDownOptions options={passengerOptions} selected={passengers} setUpdate={setPassengers} dropdownName="Passengers" />
                        </div>

                        {/* Baggage */}
                        <div className="flex items-start flex-col flex-1 w-100">
                            <span className="text-sm md:text-[14px]">{t("booking_baggage")}</span>
                            <CustomDropDownOptions options={baggageOptions} selected={baggage} setUpdate={setBaggage} dropdownName="Baggage" />
                        </div>

                        {/* Child Seat */}
                        <div className="flex items-start flex-col flex-1 w-100">
                            <span className="text-sm md:text-[14px]">{t("booking_child_seat")}</span>
                            <CustomDropDownOptions options={childOptions} selected={childSeat} setUpdate={setChildSeat} dropdownName="Child" />
                        </div>
                    </div>

                    {/* Render child seat dropdowns dynamically */}
                    {Array.from({ length: childSeat }).map((_, index) => (
                        <div key={index} className="w-full">
                            <span className="text-sm md:text-[14px]">{t("booking_child_seat")} #{index + 1}</span>
                            <CustomDropDownOptions
                                options={childSeatOptions}
                                selected={childSeatOptions[0].value}
                                dropdownName="Seat"
                                setUpdate={(value) => handleChildSeatChange(value, index)}
                            />
                            {childSeatError && childSeatValues[index] === "choose" && (
                                <p className="text-red-500 text-sm mt-1">Please select a valid child seat.</p>
                            )}
                        </div>
                    ))}

                    <div className='flex gap-2 justify-center items-center w-full'>
                        {/* Flight Number */}
                        <div className="relative w-full">
                            <MdFlight className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                            <input
                                type="text"
                                placeholder={`${t("booking_flight_number")}`}
                                value={flightNumber}
                                onChange={(e) => setFlightNumber(e.target.value)}
                                className="w-[100%] border-[1px] border-black p-2 pl-10 outline-none rounded-sm"
                            />
                        </div>

                        {/* Name Board */}
                        <div className="relative w-full">
                            <SiNamesilo className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                            <input
                                type="text"
                                placeholder={`${t("booking_name_board")}`}
                                value={nameBoard}
                                onChange={(e) => setNameBoard(e.target.value)}
                                className="w-[100%] border-[1px] border-black p-2 pl-10 outline-none rounded-sm"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Notes for Driver */}
            <div className="mb-4">
                <textarea
                    placeholder={`${t("booking_driver_note")}`}
                    className="border-[1px] border-black p-2 outline-none w-full"
                    rows="5"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                ></textarea>
            </div>

            {/* Submit Button */}
            <div className='flex justify-end'>
                <button
                    className="bg-[#da291c] hover:bg-black text-white tracking-[2px] py-2 px-8 uppercase"
                    type='submit'
                >
                    {t("booking_payment_btn")}
                </button>
            </div>
        </form>
    );
};

export default PassengerDetailsForm;
